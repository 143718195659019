import axios, { AxiosResponse } from 'axios'
import { useAtomValue } from 'jotai'
import { useQuery } from 'react-query'
import { isSameDay } from 'date-fns'
import { fatal } from '@/utils'
import { CHAIN_INFO_LIST } from '@/config'
import {
  selectedAccountAtom,
  strideAccountAtom,
  osmosisAccountAtom,
  isMsgTransfer,
  isMsgLiquidStake,
  Account,
  TxMetaData,
  TxQueryResponse
} from '@/atoms/Wallet'
import { queryKeys } from '@/query-keys'
import { createIbcMetaData } from './create-ibc-meta-data'
import { createIbcLiquidityPoolMetaData } from './create-ibc-liquidity-pool-meta-data'
import { createIbcWithdrawStTokenMetaData } from './create-ibc-withdraw-st-token-meta-data'
import { createLiquidStakeMetaData } from './create-liquid-stake-meta-data'
import { createRedemptionMetaData } from './create-redemption-meta-data'
import { handleIbc } from './handle-ibc'
import { handleIbcLiquidityPool } from './handle-ibc-liquidity-pool'
import { handleIbcWithdrawStToken } from './handle-ibc-withdraw-st-token'
import { handleLiquidStake } from './handle-liquid-stake'
import { EpochTrackerResponse, UserRedemptionRecordResponse } from './types'

const useTransactionHistoryQuery = () => {
  const selectedAccount = useAtomValue(selectedAccountAtom)

  const strideAccount = useAtomValue(strideAccountAtom)

  const osmosisAccount = useAtomValue(osmosisAccountAtom)

  const handleQueryRedemptionRecords = async () => {
    if (!strideAccount || !selectedAccount) {
      throw fatal('Unable to query redemption records while wallet is disconnected')
    }

    const selectedChainInfo = CHAIN_INFO_LIST[selectedAccount.currency.coinDenom]

    const strideChainInfo = CHAIN_INFO_LIST[strideAccount.currency.coinDenom]

    const instance = axios.create({
      baseURL: strideChainInfo.rest
    })

    const epochTrackerResponse = await instance.get<EpochTrackerResponse>(
      '/Stride-Labs/stride/stakeibc/epoch_tracker/day'
    )

    const userRedemptionRecordResponse = await instance.get<UserRedemptionRecordResponse>(
      [
        `/Stride-Labs/stride/records/user_redemption_record_for_user`,
        selectedChainInfo.chainId,
        epochTrackerResponse.data.epoch_tracker.epoch_number,
        strideAccount.key.bech32Address,
        100
      ].join('/')
    )

    return userRedemptionRecordResponse.data.user_redemption_record.map((record) => {
      return createRedemptionMetaData({
        record,
        selectedAccount,
        strideAccount,
        osmosisAccount
      })
    })
  }

  const handleFetchAddressTransactions = async (account: Account): Promise<TxMetaData[]> => {
    if (!selectedAccount || !strideAccount) {
      throw fatal('Unable to query pending transactions while disconnected.')
    }

    let pageKey = null

    let txs: TxMetaData[] = []

    const now = new Date()

    const chainInfo = CHAIN_INFO_LIST[account.currency.coinDenom]

    const instance = axios.create({
      baseURL: chainInfo.rest
    })

    while (true) {
      const params = new URLSearchParams()
      params.append('pagination.key', `${pageKey}`)
      params.append('pagination.reverse', 'true')
      // Descending https://github.com/Stride-Labs/stride/blob/f7ced7ae5cf769c1bd2799f6038a03031fee7015/third_party/proto/cosmos/tx/v1beta1/service.proto#L56
      params.append('order_by', '2')
      params.append('events', `message.sender='${account.key.bech32Address}'`)

      const response: AxiosResponse<TxQueryResponse> = await instance.get(`cosmos/tx/v1beta1/txs?${params}`)

      // There are no more items
      if (response.data.tx_responses.length === 0) {
        return txs
      }

      for (const res of response.data.tx_responses) {
        // It's outside of our query scope
        if (!isSameDay(new Date(res.timestamp), now)) {
          return txs
        }

        const message = res.tx?.body?.messages[0]

        // This is likely coming only from Stride
        if (
          isMsgLiquidStake(message) &&
          handleLiquidStake({
            message,
            strideAccount,
            selectedAccount,
            osmosisAccount
          })
        ) {
          txs.push(
            createLiquidStakeMetaData({
              response: res,
              message,
              strideAccount,
              selectedAccount,
              osmosisAccount
            })
          )
        } else if (
          isMsgTransfer(message) &&
          handleIbc({
            message,
            strideAccount,
            selectedAccount,
            osmosisAccount
          })
        ) {
          txs.push(
            createIbcMetaData({
              response: res,
              message,
              strideAccount,
              selectedAccount,
              osmosisAccount
            })
          )
        } else if (
          isMsgTransfer(message) &&
          handleIbcLiquidityPool({
            message,
            strideAccount,
            selectedAccount,
            osmosisAccount
          })
        ) {
          txs.push(
            createIbcLiquidityPoolMetaData({
              response: res,
              message,
              strideAccount,
              selectedAccount,
              osmosisAccount
            })
          )
        } else if (
          isMsgTransfer(message) &&
          handleIbcWithdrawStToken({
            message,
            strideAccount,
            selectedAccount,
            osmosisAccount
          })
        ) {
          txs.push(
            createIbcWithdrawStTokenMetaData({
              response: res,
              message,
              strideAccount,
              selectedAccount,
              osmosisAccount
            })
          )
        }
      }

      // There is no next page
      if (!(pageKey = response.data.pagination?.next_key)) {
        return txs
      }
    }
  }

  const handleQueryPendingTransactions = async () => {
    if (!strideAccount || !selectedAccount) {
      throw fatal('Unable to fetch pending transactions while disconnected.')
    }

    const txs = await Promise.all([
      handleQueryRedemptionRecords(),
      handleFetchAddressTransactions(selectedAccount),
      handleFetchAddressTransactions(strideAccount)
    ])

    return txs.flat()
  }

  const addresses = {
    strideAccount: strideAccount?.key.bech32Address ?? '',
    selectedAccount: selectedAccount?.key.bech32Address ?? '',
    osmosisAccount: osmosisAccount?.key.bech32Address ?? ''
  }

  return useQuery(queryKeys.transactionHistoryByAddresses({ addresses: addresses }), handleQueryPendingTransactions, {
    enabled: Boolean(strideAccount && selectedAccount),
    staleTime: 60_000
  })
}

export { useTransactionHistoryQuery }
