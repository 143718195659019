import { selectedAccountDenomAtom, strideAccountSelectedCoinAmountAtom, useWalletConnect } from '@/atoms/Wallet'
import { StepperModalContent, useStepperContext } from '@/components'
import { Button } from '@mantine/core'
import { useAtom } from 'jotai'
import BigNumber from 'bignumber.js'
import React, { useMemo, useRef } from 'react'
import { ibcTransferAmountAtom, isIbcTransferSkippedAtom, sendTokenTxRawAtom } from './atoms'
import { STAKING_STEP_THREE } from './constants'
interface StakeModalStepOneProps {
  amount: number
}

const StakeModalStepOne: React.FC<StakeModalStepOneProps> = ({ amount }) => {
  const { signSendToken, isSigningSendToken, signSendTokenError } = useWalletConnect()

  const [denom] = useAtom(selectedAccountDenomAtom)

  const [strideAccountSelectedCoinBalance] = useAtom(strideAccountSelectedCoinAmountAtom)

  const [, setSendTokenTxRaw] = useAtom(sendTokenTxRawAtom)

  const [, setIsIbcTransferSkipped] = useAtom(isIbcTransferSkippedAtom)

  const [, setIbcTransferAmount] = useAtom(ibcTransferAmountAtom)

  const isApplyingStrideBalanceRef = useRef(false)

  const isViableToApplyStrideBalance = strideAccountSelectedCoinBalance > 0

  const isViableToUseFullStrideBalance = strideAccountSelectedCoinBalance >= amount

  const appliedStrideBalanceAmount = useMemo(() => {
    return new BigNumber(amount).minus(strideAccountSelectedCoinBalance)
  }, [amount, strideAccountSelectedCoinBalance])

  const formattedAmount = `${amount} ${denom}`

  const { start, nextStep, jumpStep, forceClose } = useStepperContext()

  const handleStart = async () => {
    start()

    const ibcTransferAmount = isApplyingStrideBalanceRef.current ? appliedStrideBalanceAmount.toNumber() : amount

    setIbcTransferAmount(ibcTransferAmount)

    const raw = await signSendToken({ amount: ibcTransferAmount, mode: 'deposit' })

    setSendTokenTxRaw(raw)

    nextStep()
  }

  const handleStartTransfer = async () => {
    handleStart()
  }

  const handleApplyStrideBalance = () => {
    isApplyingStrideBalanceRef.current = true
    handleStart()
  }

  const handleUseStrideBalance = () => {
    setIsIbcTransferSkipped(true)
    start()
    jumpStep(STAKING_STEP_THREE)
  }

  const handleRetrySignSendToken = async () => {
    handleStart()
  }

  if (signSendTokenError) {
    return (
      <StepperModalContent
        title="Transaction error"
        description="This transfer could not be completed. Your tokens have not been moved."
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Back to Stride
            </Button>

            <Button color="dark" variant="outline" onClick={handleRetrySignSendToken}>
              Try again
            </Button>
          </>
        }
      />
    )
  }

  if (isSigningSendToken) {
    return (
      <StepperModalContent
        title="Approve the transaction in your wallet to continue"
        description={`This will start the transfer of your ${denom} tokens to Stride to start the staking process.`}
      />
    )
  }

  if (isViableToUseFullStrideBalance) {
    return (
      // User has enough tokens on Stride to fund the entire transaction
      <StepperModalContent
        title="Your existing Stride balance will be used for this stake"
        description={`Good news! You already have enough ${denom} on Stride to fund this stake, so staking can start without transferring additional tokens.`}
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Cancel
            </Button>

            <Button onClick={handleUseStrideBalance}>Continue</Button>
          </>
        }
      />
    )
  }

  if (isViableToApplyStrideBalance) {
    return (
      // User can cover some of the transaction with their existing Stride balance, but not enough to fund the entire transaction
      // The rest of the transaction will be funded with IBC
      <StepperModalContent
        title={`Your existing Stride balance will be applied to this stake`}
        description={`You already have ${new BigNumber(strideAccountSelectedCoinBalance)
          .decimalPlaces(3)
          .toString()} ${denom} on Stride. The remaining ${appliedStrideBalanceAmount
          .decimalPlaces(3)
          .toString()} ${denom} will be transferred to Stride.`}
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Cancel
            </Button>

            <Button onClick={handleApplyStrideBalance}>Continue</Button>
          </>
        }
      />
    )
  }

  return (
    <StepperModalContent
      // User must fund the entire transaction with IBC and has no tokens on Stride
      title={`Nice! You’re about to liquid stake ${formattedAmount} on Stride`}
      description="This should take about a minute and will require 2 wallet approvals"
      actions={<Button onClick={handleStartTransfer}>Start staking</Button>}
    />
  )
}

export { StakeModalStepOne }
