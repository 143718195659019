import axios from 'axios'
import BigNumber from 'bignumber.js'
import { useQuery } from 'react-query'
import { useAtomValue } from 'jotai'
import { fatal } from '@/utils'
import { CHAIN_CONFIG } from '@/config'
import { queryKeys } from '@/query-keys'
import { selectedCoinDenomAtom } from '@/atoms/Wallet'

interface OsmosisPoolData {
  pool: {
    pool_assets: {
      weight: string
      token: {
        denom: string
        amount: string
      }
    }[]
  }
}

const useOsmosisMarketPriceQuery = () => {
  const selectedCoinDenom = useAtomValue(selectedCoinDenomAtom)

  const poolId = CHAIN_CONFIG[selectedCoinDenom].poolId

  const handleRequest = async (): Promise<{ value: number }> => {
    const response = await axios.get<OsmosisPoolData>(
      `https://osmosis-api.polkachu.com/osmosis/gamm/v1beta1/pools/${poolId}`
    )

    const denomAsset = response.data.pool.pool_assets.find((asset) => {
      return asset.token.denom === CHAIN_CONFIG[selectedCoinDenom].osmosisIbcDenom
    })

    const stakedDenomAsset = response.data.pool.pool_assets.find((asset) => {
      return asset.token.denom === CHAIN_CONFIG[selectedCoinDenom].osmosisStakedIbcDenom
    })

    if (!denomAsset || !stakedDenomAsset) {
      throw fatal(`Unable to get denom and staked denom price for pool ${poolId} from Osmosis.`)
    }

    return { value: new BigNumber(denomAsset.token.amount).dividedBy(stakedDenomAsset.token.amount).toNumber() }
  }

  return useQuery(queryKeys.stakingStatsOsmosisMarketPriceByDenom({ denom: selectedCoinDenom }), handleRequest, {
    enabled: Boolean(poolId)
  })
}

export { useOsmosisMarketPriceQuery }
