import React from 'react'
import { useAtom } from 'jotai'
import { Anchor, Button } from '@mantine/core'
import { useMount } from '@/hooks'
import { StepperModalContent, useStepperContext } from '@/components'
import { selectedAccountDenomAtom, useWalletConnect } from '@/atoms/Wallet'
import { unstakeTokenTxRawAtom } from './atoms'
import { claimMetaDataAtom, unstakeAmountAtom } from '@/page-components/Stake/atoms'
import { STAKING_STEP_FOUR, UNSTAKE_TRANSACTION_BEGIN_LEARN_MORE_URL } from './constants'

const UnstakeModalStepOne: React.FC = () => {
  const { signUnstakeTokens, isSigningUnstakeTokens, signUnstakeTokensError } = useWalletConnect()

  const [amount] = useAtom(unstakeAmountAtom)

  const [, setUnstakeTokenTxRaw] = useAtom(unstakeTokenTxRawAtom)

  const [denom] = useAtom(selectedAccountDenomAtom)

  const stakedDenom = `st${denom}`

  const [claimMetaData] = useAtom(claimMetaDataAtom)

  const { start, nextStep, jumpStep, close, forceClose } = useStepperContext()

  const handleStart = async () => {
    start()
    setUnstakeTokenTxRaw(await signUnstakeTokens(amount))
    nextStep()
  }

  const handleJump = () => {
    start()
    jumpStep(STAKING_STEP_FOUR)
  }

  useMount(() => {
    if (claimMetaData) handleJump()
  })

  const handleRetrySignStakeTokens = () => {
    handleStart()
  }

  if (signUnstakeTokensError) {
    return (
      <StepperModalContent
        title="Transaction error"
        description="Your tokens couldn’t be unstaked at this time. Try again in a few minutes."
        actions={
          <>
            <Button variant="outline" color="dark" onClick={forceClose}>
              Back to Stride
            </Button>

            <Button color="dark" onClick={handleRetrySignStakeTokens}>
              Try again
            </Button>
          </>
        }
      />
    )
  }

  if (isSigningUnstakeTokens) {
    return (
      <StepperModalContent
        title="Confirm the transaction in your wallet to continue"
        description={`This will start the transfer of your ${stakedDenom} tokens out of your wallet and start the unstaking process`}
      />
    )
  }

  return (
    <StepperModalContent
      title="In a rush? Unstaking will take about 3 weeks to complete."
      description={
        <>
          If you'd like access to your {denom} sooner, you can trade {stakedDenom} directly on a DEX like Osmosis.{' '}
          <Anchor href={UNSTAKE_TRANSACTION_BEGIN_LEARN_MORE_URL} target="_blank">
            Learn more
          </Anchor>
        </>
      }
      actions={
        <>
          <Button variant="outline" color="dark" onClick={close}>
            Cancel
          </Button>
          <Button onClick={handleStart}>Continue unstake</Button>
        </>
      }
    />
  )
}

export { UnstakeModalStepOne }
