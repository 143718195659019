import {
  selectedAccountAtom,
  selectedAccountDenomAtom,
  strideAccountAtom,
  strideAccountSelectedStakedCoinAmountAtom
} from '@/atoms/Wallet'
import BigNumber from 'bignumber.js'
import { useFormContext } from '@/components'
import { Box, Button, Divider, Group, Space, Text, TextInput, Tooltip, UnstyledButton } from '@mantine/core'
import { useDidUpdate } from '@mantine/hooks'
import { useAtom } from 'jotai'
import { useMemo, useState } from 'react'
import { isUnstakingModalOpenAtom, unstakeAmountAtom } from './atoms'
import { StakeEstimate } from './StakeEstimate'
import { UnstakeFormType } from './useUnstakeForm'
import { useHostZoneQuery } from '@/queries'
import { useRedemptionRecordQuery } from './queries'

const UnstakeForm: React.FC = () => {
  const [strideAccount] = useAtom(strideAccountAtom)

  const [selectedAccount] = useAtom(selectedAccountAtom)

  const [stakedAmount] = useAtom(strideAccountSelectedStakedCoinAmountAtom)

  const [denom] = useAtom(selectedAccountDenomAtom)

  const [, setIsOpen] = useAtom(isUnstakingModalOpenAtom)

  const [, setAmount] = useAtom(unstakeAmountAtom)

  const [isFocused, setIsFocused] = useState(false)

  const { values, errors, setFieldValue, getInputProps, onSubmit, validate } = useFormContext<UnstakeFormType>()

  const { redemptionRecord, isRedemptionRecordLoading } = useRedemptionRecordQuery()

  const { data: hostZone } = useHostZoneQuery()

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  const handleClickMax = () => {
    const max = Math.max(stakedAmount, 0)
    setFieldValue('amount', String(max))
  }

  const handleSubmit = () => {
    setAmount(Number(values.amount))
    setIsOpen(true)
  }

  useDidUpdate(() => {
    if (!isFocused) return
    // We only want to validate if the user changed something,
    // but not if reset by a successful unstake
    validate()
  }, [values, isFocused])

  const isUnstakingTextEnabled = useMemo(() => {
    if (hostZone == null) return false
    return Number(hostZone.staked_bal) === 0
  }, [hostZone])

  const isDisabled = useMemo(() => {
    if (strideAccount == null || selectedAccount == null) return true
    if (redemptionRecord) return true
    return Boolean(errors.amount)
  }, [strideAccount, errors, redemptionRecord])

  const stakedDenom = `st${denom}`

  return (
    <>
      <form onSubmit={onSubmit(handleSubmit)}>
        <Text align="center" color="gray">
          Unstake your {stakedDenom} tokens in exchange for {denom}.
          {isUnstakingTextEnabled && (
            <span>&nbsp;Unstaking will be enabled after the first full delegation cycle, which takes 4 days.</span>
          )}
        </Text>

        <Space h="lg" />

        <Box>
          <Text inline sx={(t) => ({ color: t.colors.gray[7] })}>
            Amount to unstake:
          </Text>

          <Space h="xs" />

          <Text weight={700} size="xl" inline sx={(t) => ({ color: t.colors.gray[9] })}>
            {stakedDenom}
          </Text>
        </Box>

        <Space h="sm" />

        <TextInput
          required
          placeholder="0"
          size="xl"
          radius="md"
          sx={{ input: { fontWeight: 'bold', textAlign: 'right' } }}
          onFocus={handleFocus}
          onBlur={handleBlur}
          {...getInputProps('amount')}
        />

        {Boolean(strideAccount) && (
          <>
            <Space h="xs" />

            <Group position="apart" align="center">
              <Text sx={(t) => ({ color: t.colors.gray[7] })}>
                Available in wallet: {new BigNumber(stakedAmount).decimalPlaces(5).toString()} {stakedDenom}
              </Text>

              <UnstyledButton onClick={handleClickMax}>
                <Text color="pink">Add max</Text>
              </UnstyledButton>
            </Group>
          </>
        )}

        <Space h="lg" />

        <Divider />

        <Space h="lg" />

        <Group position="apart" align="center">
          <Box>
            <Text inline sx={(t) => ({ color: t.colors.gray[7] })}>
              What you'll get:
            </Text>

            <Space h="xs" />

            <Text weight={700} size="xl" inline sx={(t) => ({ color: t.colors.gray[9] })}>
              {denom}
            </Text>
          </Box>

          <Text
            weight={700}
            size="xl"
            sx={(t) => ({ paddingRight: t.other.stakeForm.amountPaddingRight, color: t.colors.gray[9] })}
            inline>
            <StakeEstimate amount={Number(values.amount)} mode="unstake" />
          </Text>
        </Group>

        <Space h="lg" />

        <Tooltip
          label="You can only redeem once per day"
          disabled={!redemptionRecord}
          sx={{ width: '100%' }}
          withinPortal>
          <Button
            type="submit"
            fullWidth
            size="lg"
            radius="md"
            disabled={isDisabled}
            loading={isRedemptionRecordLoading}>
            Unstake
          </Button>
        </Tooltip>
      </form>
    </>
  )
}

export { UnstakeForm }
