import React from 'react'
import { useAtom } from 'jotai'
import { Button } from '@mantine/core'
import { useMount } from '@/hooks'
import { StepperModalContent, useStepperContext } from '@/components'
import { selectedAccountAtom, strideAccountAtom, useWalletConnect } from '@/atoms/Wallet'
import { notify } from '@/atoms/Notifications'
import { unstakeTokenTxRawAtom } from './atoms'
import { fatal } from '@/utils'

const UnstakeModalStepTwo: React.FC = () => {
  const { broadcastUnstakeTokens, isBroadcastingUnstakeTokens, broadcastUnstakeTokensError } = useWalletConnect()

  const [unstakeTokenTxRaw] = useAtom(unstakeTokenTxRawAtom)

  const [selectedAccount] = useAtom(selectedAccountAtom)

  const [strideAccount] = useAtom(strideAccountAtom)

  const { nextStep, forceClose, handleClose } = useStepperContext()

  const handleStep = async () => {
    if (unstakeTokenTxRaw == null) {
      throw fatal('Send token transaction was being broadcasted to the blockchain without being signed.')
    }

    await broadcastUnstakeTokens(unstakeTokenTxRaw)
    nextStep()
  }

  const handleCloseCallback = () => {
    if (!selectedAccount || !strideAccount) {
      throw fatal('Unable to redeem stake while disconnected')
    }

    // If it was closed after the broadcast completed, it doesn't matter
    if (!isBroadcastingUnstakeTokens) {
      return
    }

    notify.progress('Transaction minimized', "We'll let you know when the unstake completes.")
  }

  useMount(() => {
    handleStep()
  })

  handleClose(handleCloseCallback)

  const handleRetryBroadcastStakeTokens = () => {
    handleStep()
  }

  if (broadcastUnstakeTokensError) {
    return (
      <StepperModalContent
        title="Transaction error"
        description="Your tokens couldn’t be unstaked at this time. Try again in a few minutes."
        actions={
          <>
            <Button variant="outline" color="dark" onClick={forceClose}>
              Back to Stride
            </Button>

            <Button color="dark" onClick={handleRetryBroadcastStakeTokens}>
              Try again
            </Button>
          </>
        }
      />
    )
  }

  return (
    <StepperModalContent
      title="Unstaking your tokens..."
      description="Just a few seconds, unless the network is congested."
    />
  )
}

export { UnstakeModalStepTwo }
