import { useMutation, useQueryClient } from 'react-query'
import { useAtomValue } from 'jotai'
import { fatal, disregard } from '@/utils'
import { notify } from '@/atoms/Notifications'
import {
  selectedAccountAtom,
  strideAccountAtom,
  osmosisAccountAtom,
  useUpdateAccountBalances,
  traceIbcStatus,
  IBCTransferStatus
} from '@/atoms/Wallet'
import { queryKeys } from '@/query-keys'
import { MutationParameters } from './types'

// [sign/broadcast] mutations and [trace] mutation are a 2-part process.
// First, if [sign/broadcast] doesn't succeed within the UI time limit, [trace] mutation will continue
// the [tracing] process so user can continue when the ibc transfer completes if they haven't closed the modal yet.
// We can use the `isLoading` status provided by [trace] mutation to display the "Return Later" screen.
const useTraceIbcTransfer = ({ transactionRef }: MutationParameters) => {
  const client = useQueryClient()

  const selectedAccount = useAtomValue(selectedAccountAtom)

  const strideAccount = useAtomValue(strideAccountAtom)

  const osmosisAccount = useAtomValue(osmosisAccountAtom)

  const { updateAccountBalances } = useUpdateAccountBalances()

  return useMutation(async (): Promise<{ status: IBCTransferStatus }> => {
    if (!strideAccount || !selectedAccount || !osmosisAccount) {
      throw fatal('You are unable to send token without connecting your wallet.')
    }

    if (!transactionRef.current) {
      throw fatal('Unable to trace ibc status while transaction is empty.')
    }

    // @TODO: Handle timeout state if this doesn't succeed. The original implementation
    // from the ibc/staking modal doesn't have this yet neither. Add display states for
    const status = await traceIbcStatus({ account: strideAccount, tx: transactionRef.current }, { timeout: false })

    await client.invalidateQueries(
      queryKeys.transactionHistoryIbcStatusByHash({ hash: transactionRef.current.transactionHash })
    )

    try {
      await updateAccountBalances(strideAccount)
      await updateAccountBalances(selectedAccount)
      await updateAccountBalances(osmosisAccount)
    } catch (e) {
      notify.error(`An error occured while fetching your updated account balance. Please refresh the page.`)
      disregard(e)
    }

    return { status }
  })
}

export { useTraceIbcTransfer }
