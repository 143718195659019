import { isAfter } from 'date-fns'
import axios, { AxiosError } from 'axios'
import { useQuery, useQueryClient } from 'react-query'
import { useAtom } from 'jotai'
import { CHAIN_INFO_LIST } from '@/config'
import { selectedAccountAtom, strideAccountAtom } from '@/atoms/Wallet'
import { useInterval } from '@/hooks'
import { fatal, fromNanosecondTimestamp } from '@/utils'
import { queryKeys } from '@/query-keys'

interface EpochTrackerResponse {
  epoch_tracker: {
    epoch_identifier: string
    epoch_number: string
    next_epoch_start_time: string
    duration: string
  }
}

interface UserRedemptionRecordResponse {
  user_redemption_record: {
    id: string
    sender: string
    receiver: string
    amount: string
    denom: string
    host_zone_id: string
    epoch_number: string
    claim_is_pending: boolean
  }
}

// Fetches redemption record for the current epoch.
// Refetches updated epoch number when the current one expires.
const useRedemptionRecordQuery = () => {
  const [strideAccount] = useAtom(strideAccountAtom)

  const [selectedAccount] = useAtom(selectedAccountAtom)

  const client = useQueryClient()

  const handleFetchEpochTracker = async () => {
    if (!strideAccount) {
      throw fatal('Unable to verify unstake viability while disconnected')
    }

    const strideChainInfo = CHAIN_INFO_LIST[strideAccount.currency.coinDenom]

    const instance = axios.create({ baseURL: strideChainInfo.rest })

    return await instance.get<EpochTrackerResponse>('/Stride-Labs/stride/stakeibc/epoch_tracker/day')
  }

  const { data: epochTracker, isLoading: isEpochTrackerLoading } = useQuery(
    queryKeys.unstakeFormEpochTrackerBase,
    handleFetchEpochTracker,
    { enabled: Boolean(strideAccount), select: (response) => response.data.epoch_tracker }
  )

  const handleFetchRedemptionRecord = async () => {
    if (!strideAccount || !selectedAccount) {
      // User should not be able to exit while unstake wizard is on-going
      throw fatal('Unable to verify unstake viability while disconnected')
    }

    if (!epochTracker) {
      throw fatal('Unable to verify unstake viability without epoch information')
    }

    const selectedChainInfo = CHAIN_INFO_LIST[selectedAccount.currency.coinDenom]

    const strideChainInfo = CHAIN_INFO_LIST[strideAccount.currency.coinDenom]

    const instance = axios.create({ baseURL: strideChainInfo.rest })

    const redemptionRecordId = [
      selectedChainInfo.chainId,
      epochTracker.epoch_number,
      strideAccount.key.bech32Address
    ].join('.')

    try {
      return await instance.get<UserRedemptionRecordResponse>(
        `/Stride-Labs/stride/records/user_redemption_record/${redemptionRecordId}`
      )
    } catch (e) {
      // When the redemption record doesn't exist, the endpoint
      // doesn't implement a normal 404 error code. It acts
      // as if the "method" we're requesting doesn't exist.
      // In this case, we want to disregard the error
      if (e instanceof AxiosError && e.response?.status === 400) {
        return null
      }
    }
  }

  const { data: redemptionRecord, isLoading: isRedemptionRecordLoading } = useQuery(
    queryKeys.unstakeFormRedemptionRecord({
      address: selectedAccount?.key.bech32Address ?? '',
      epochNumber: epochTracker?.epoch_number ?? ''
    }),
    handleFetchRedemptionRecord,
    {
      enabled: Boolean(strideAccount && selectedAccount && epochTracker),
      select: (response) => response?.data.user_redemption_record
    }
  )

  // Invalidates cache if we're past the next epoch start time
  // @TODO: Play with react-query staleTime instead (?)
  useInterval(
    () => {
      if (redemptionRecord == null || epochTracker == null) {
        return
      }

      const nextEpochStartTimeTimestamp = fromNanosecondTimestamp(epochTracker.next_epoch_start_time)

      if (isAfter(new Date(), new Date(nextEpochStartTimeTimestamp))) {
        client.invalidateQueries(queryKeys.unstakeFormEpochTrackerBase)
      }
    },
    epochTracker && redemptionRecord ? 30 * 1000 : null
  )

  return {
    redemptionRecord: redemptionRecord,
    isRedemptionRecordLoading: isRedemptionRecordLoading || isEpochTrackerLoading
  }
}

export { useRedemptionRecordQuery }
