import React from 'react'
import BigNumber from 'bignumber.js'
import { useAtom } from 'jotai'
import { Box, Button, Space } from '@mantine/core'
import { CHAIN_INFO_LIST } from '@/config'
import { DataList, DataListItem, TransactionCard } from '@/components'
import { RedemptionMetaData, RedemptionStatus } from '@/atoms/Wallet'
import { claimMetaDataAtom, isUnstakingModalOpenAtom } from '@/page-components/Stake/atoms'
interface RedemptionDataMetaCardProps {
  meta: RedemptionMetaData
}

const RedemptionMetaDataCard: React.FC<RedemptionDataMetaCardProps> = ({ meta }) => {
  const [, setClaimMetaData] = useAtom(claimMetaDataAtom)

  const [, setIsUnstakingModalOpen] = useAtom(isUnstakingModalOpenAtom)

  const handleClaim = () => {
    setClaimMetaData(meta)
    setIsUnstakingModalOpen(true)
  }

  const { chainName } = CHAIN_INFO_LIST[meta.values.denom]

  const formattedAmount = `${new BigNumber(meta.values.amount).decimalPlaces(3).toString()} ${meta.values.denom}`

  const title = meta.values.isClaimable ? `${meta.values.denom} ready to claim` : `Unstaking ${formattedAmount}`

  const description: Record<RedemptionStatus, (meta: RedemptionMetaData) => React.ReactNode> = {
    pending: (meta) => (
      <span>
        You'll soon receive {formattedAmount} on {chainName} at{' '}
        <Box sx={{ display: 'inline', wordBreak: 'break-all' }}>{meta.values.receiver}.</Box>
      </span>
    ),
    complete: (meta) => (
      <span>
        You received {formattedAmount} on {chainName} at{' '}
        <Box sx={{ display: 'inline', wordBreak: 'break-all' }}>{meta.values.receiver}.</Box>
      </span>
    )
  }

  return (
    <TransactionCard
      status={meta.values.isClaimable ? 'complete' : 'pending'}
      title={title}
      description={meta.values.isClaimable ? description['complete'](meta) : description['pending'](meta)}
      action={
        meta.values.isClaimable ? (
          <Button variant="outline" color="dark" onClick={handleClaim}>
            Claim tokens
          </Button>
        ) : null
      }>
      {!meta.values.isClaimable && meta.estimatedAt && (
        <>
          <Space h="md" />

          <DataList>
            <DataListItem label="Estimated Complete Date" value={meta.estimatedAt} />
          </DataList>
        </>
      )}
    </TransactionCard>
  )
}

export { RedemptionMetaDataCard }
