import { useMemo } from 'react'
import { useAtom } from 'jotai'
import { useForm, zodResolver } from '@mantine/form'
import z from 'zod'
import { strideAccountSelectedStakedCoinAmountAtom } from '@/atoms/Wallet'

export interface UnstakeFormType {
  amount: string
}

const useUnstakeForm = () => {
  const [stakedAmount] = useAtom(strideAccountSelectedStakedCoinAmountAtom)

  const schema = useMemo(() => {
    return z.object({
      amount: z.preprocess(
        (v) => Number(z.string().parse(v)),
        z
          .number({
            required_error: 'Amount is required.',
            invalid_type_error: 'Amount must be a number.'
          })
          .min(0.001, 'Amount must have a value more than 0.001.')
          .max(stakedAmount, 'Amount exceeds your balance.')
      )
    })
  }, [stakedAmount])

  return useForm<UnstakeFormType>({
    schema: zodResolver(schema),
    initialValues: { amount: '' }
  })
}

export { useUnstakeForm }
