import React, { useMemo } from 'react'
import { useAtom } from 'jotai'
import { Box, Space, Group, Text } from '@mantine/core'
import { selectedCoinDenomAtom } from '@/atoms/Wallet'
import { useStakeStatsQuery } from '@/queries'
import { TextLoader } from '@/components'
import { CoinSelector } from '@/page-components/CoinSelector'
import { useMediumScreen } from '@/hooks'
import BigNumber from 'bignumber.js'

const StakeCoinSelector: React.FC = () => {
  const [selectedCoinDenom] = useAtom(selectedCoinDenomAtom)

  const { data: stakeStats, isLoading: isStakeStatsLoading } = useStakeStatsQuery()

  const strideYield = useMemo(() => {
    if (stakeStats == null) return '0%'
    const { strideYield } = stakeStats[selectedCoinDenom]
    return `${new BigNumber(strideYield).multipliedBy(100).decimalPlaces(2).toString()}%`
  }, [selectedCoinDenom, stakeStats])

  const isMediumScreen = useMediumScreen()

  return (
    <Group position="apart" align="center" pb="md">
      <CoinSelector />

      <Box hidden={!isMediumScreen}>
        <Text weight={600} inline>
          APY
        </Text>
        <Space h="xs" />
        <TextLoader loading={isStakeStatsLoading} sx={{ fontSize: 26 }} weight={700} inline>
          {strideYield}
        </TextLoader>
      </Box>
    </Group>
  )
}

export { StakeCoinSelector }
