import React from 'react'
import BigNumber from 'bignumber.js'
import { useAtomValue } from 'jotai'
import { Button } from '@mantine/core'
import { useMount } from '@/hooks'
import { fatal } from '@/utils'
import { CHAIN_INFO_LIST } from '@/config'
import { selectedAccountAtom } from '@/atoms/Wallet'
import { notify } from '@/atoms/Notifications'
import { StepperModalContent, useStepperContext } from '@/components'
import { withdrawStTokenAmountAtom, withdrawStTokenModalIsOpenAtom } from '../atoms'
import { useWithdrawStToken } from './WithdrawStTokenContext'

const WithdrawStTokenStepTwo: React.FC = () => {
  const selectedAccount = useAtomValue(selectedAccountAtom)

  const amount = useAtomValue(withdrawStTokenAmountAtom)

  const isOpen = useAtomValue(withdrawStTokenModalIsOpenAtom)

  const {
    broadcastWithdrawStToken,
    isBroadcastingWithdrawStToken,
    broadcastWithdrawStTokenError,
    traceIbcStatus,
    isIbcStatusLoading
  } = useWithdrawStToken()

  const { complete, handleClose, forceClose, close } = useStepperContext()

  if (!selectedAccount) {
    throw fatal('Unable to complete withdrawal of st tokens while disconnected.')
  }

  const chainInfo = CHAIN_INFO_LIST[selectedAccount.currency.coinDenom]

  const handleStep = async () => {
    const { status } = await broadcastWithdrawStToken()

    if (!isOpen) {
      // This function doesn't get cancelled when the modal gets closed.
      // If we get here when pool is already closed, it's likely that the stuff that
      // `traceIbcStatus` (transactionRef) needs has already been reset by the
      // modal close handler. It's unlikely this bug is affecting anyone, but this will
      // help us prevent false positives in Rollbar.
      return
    }

    if (status === 'return-later') {
      // If we're here, it means that our ibc transfer took too long and we're tracking it
      // the second time without the 30-second timeout. This way, the user can continue the
      // flow if it they haven't closed.
      //
      // We can check for the return-later status by checking using `isIbcStatusLoading`
      // to check if the ibc transfer hasn't completed and ibc tracking is still on-going.
      //
      // @TODO: Handle timeout state if this doesn't succeed. The original implementation
      // from the ibc/staking modal doesn't have this yet neither. Add display states for
      // `status === 'timeout'` for both broadcast and tracing and `ibcStatusError`
      await traceIbcStatus()
    }

    complete()
  }

  useMount(() => {
    handleStep()
  })

  const handleRetry = () => {
    handleStep()
  }

  const handleCloseCallback = () => {
    // If it was closed after the broadcast, we don't need to prepend.
    if (!isBroadcastingWithdrawStToken && !isIbcStatusLoading) {
      return
    }

    notify.progress('Transaction minimized', "We'll let you know when the transfer completes.")
  }

  handleClose(handleCloseCallback)

  const formattedStakedAmount = `${new BigNumber(amount).decimalPlaces(2).toString()} st${
    selectedAccount.currency.coinDenom
  }`

  if (isIbcStatusLoading) {
    return (
      <StepperModalContent
        title="This transaction is taking longer than usual"
        description={`If you don’t want to wait here, you can close this dialog. Your transaction will continue and you’ll be able to see status on the Stride app page.`}
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Back to Stride
            </Button>
          </>
        }
      />
    )
  }

  if (broadcastWithdrawStTokenError) {
    return (
      <StepperModalContent
        title={`Transaction error`}
        description="This transfer could not be completed. Your tokens have not been moved."
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Back to Stride
            </Button>

            <Button color="dark" variant="outline" onClick={handleRetry}>
              Try again
            </Button>
          </>
        }
      />
    )
  }

  if (isBroadcastingWithdrawStToken) {
    return (
      <StepperModalContent
        title={`Transferring st${selectedAccount.currency.coinDenom} to ${chainInfo.chainName}...`}
        description={`This could take 30 seconds or longer if the network is congested.`}
      />
    )
  }

  return (
    <StepperModalContent
      title={`Success!`}
      description={`${formattedStakedAmount} has been withdrawn to your ${chainInfo.chainName} wallet.`}
      actions={<Button onClick={close}>Done</Button>}
    />
  )
}

export { WithdrawStTokenStepTwo }
