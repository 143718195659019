import dynamic from 'next/dynamic'
import { Container, Paper, Tabs, Stack, useMantineTheme } from '@mantine/core'
import { useStakeForm } from './useStakeForm'
import { useUnstakeForm } from './useUnstakeForm'
import { StakeCoinSelector } from './StakeCoinSelector'
import { StakeForm } from './StakeForm'
import { StakeStats } from './StakeStats'
import { UnstakeForm } from './UnstakeForm'
import { UnstakeModal } from './UnstakeModal'
import { StakeLiquidityPoolModal, StakeLiquidityPoolProvider } from './StakeLiquidityPoolModal'
import { StakeTransactionHistory } from './StakeTransactionHistory'
import { WithdrawStTokenModal, WithdrawStTokenProvider } from './WithdrawStTokenModal'
import { useAutoFocusRef } from '@/hooks'
import { FormProvider } from '@/components/Form'
import { useMediaQuery } from '@mantine/hooks'

const StakeWallet = dynamic<{}>(() => import('./StakeWallet').then((module) => module.StakeWallet), { ssr: false })

const Stake: React.FC = () => {
  const stakeForm = useStakeForm()

  const unstakeForm = useUnstakeForm()

  const stakeAmountInputRef = useAutoFocusRef()

  const theme = useMantineTheme()

  const isMediumScreen = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`, true)

  const handleClaimSuccess = () => {
    unstakeForm.reset()
  }

  return (
    <>
      <Container size="sm" px={isMediumScreen ? 'lg' : 'sm'} pt={isMediumScreen ? 'xl' : 'md'}>
        <StakeCoinSelector />

        <Stack spacing="md">
          <StakeWallet />
          <StakeTransactionHistory />

          <Paper p={isMediumScreen ? 48 : 'md'} shadow="lg" withBorder>
            <Tabs tabPadding="md">
              <Tabs.Tab label="Stake">
                <FormProvider {...stakeForm}>
                  <StakeForm amountInputRef={stakeAmountInputRef} />
                </FormProvider>
              </Tabs.Tab>
              <Tabs.Tab label="Unstake">
                <FormProvider {...unstakeForm}>
                  <UnstakeForm />
                </FormProvider>
              </Tabs.Tab>
            </Tabs>
          </Paper>

          <StakeStats />
        </Stack>
      </Container>

      <UnstakeModal onSuccess={handleClaimSuccess} />

      <StakeLiquidityPoolProvider>
        <StakeLiquidityPoolModal />
      </StakeLiquidityPoolProvider>

      <WithdrawStTokenProvider>
        <WithdrawStTokenModal />
      </WithdrawStTokenProvider>
    </>
  )
}

export { Stake }
