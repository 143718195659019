import {
  selectedAccountAtom,
  selectedAccountBalanceAtom,
  selectedAccountDenomAtom,
  selectedCoinDenomAtom,
  strideAccountAtom,
  strideAccountSelectedCoinAmountAtom,
  useWalletConnect
} from '@/atoms/Wallet'
import { TextLoader, useFormContext } from '@/components'
import { Alert, Box, Button, Divider, Group, Space, Text, TextInput, Tooltip, UnstyledButton } from '@mantine/core'
import { useDidUpdate } from '@mantine/hooks'
import { useAtom } from 'jotai'
import BigNumber from 'bignumber.js'
import { useMemo, useState } from 'react'
import { CHAIN_CONFIG, EVMOS_CHAIN_INFO } from '@/config'
import { StakeModal } from './StakeModal'
import { StakeEstimate } from './StakeEstimate'
import { StakeFormType } from './useStakeForm'
import { useEligibleAirdropsQuery } from '@/queries'
import { notify } from '@/atoms/Notifications'

interface Props {
  amountInputRef: React.RefObject<HTMLInputElement>
}

const StakeForm: React.FC<Props> = ({ amountInputRef }) => {
  const [balance] = useAtom(selectedAccountBalanceAtom)

  const [strideBalance] = useAtom(strideAccountSelectedCoinAmountAtom)

  const [denom] = useAtom(selectedAccountDenomAtom)

  // @TODO: Add type-guard to `selectedAccountDenomAtom`
  // This lets us skip type-guards for now.
  const [selectedCoinDenom] = useAtom(selectedCoinDenomAtom)

  const [selectedAccount] = useAtom(selectedAccountAtom)

  const [strideAccount] = useAtom(strideAccountAtom)

  const { isFetchingSelectedAccount } = useWalletConnect()

  const { isLoading: isEligibleAirdropsLoading, error: eligibleAirdropsError } = useEligibleAirdropsQuery({
    onError: () => notify.error('We are having trouble calculating your gas amount. Please refresh the page.')
  })

  const [isFocused, setIsFocused] = useState(false)

  const [isOpen, setIsOpen] = useState(false)

  const { values, errors, setFieldValue, getInputProps, onSubmit, validate, reset } = useFormContext<StakeFormType>()

  const totalBalance = balance + strideBalance

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  const recommendedMaxAmount = useMemo(() => {
    const maxRoundedDown = new BigNumber(totalBalance)
      .minus(CHAIN_CONFIG[selectedCoinDenom].minimumGas)
      .decimalPlaces(5)

    return BigNumber.max(maxRoundedDown, 0)
  }, [totalBalance, selectedCoinDenom])

  const handleClickMax = () => {
    setFieldValue('amount', String(recommendedMaxAmount))
  }

  const handleClickHalf = () => {
    const halfRoundedDown = new BigNumber(totalBalance)
      .minus(CHAIN_CONFIG[selectedCoinDenom].minimumGas)
      .dividedBy(2)
      .decimalPlaces(5)

    const half = BigNumber.max(halfRoundedDown, 0)

    setFieldValue('amount', half.toString())
  }

  const handleSubmit = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleSuccess = () => {
    reset()
  }

  useDidUpdate(() => {
    if (!isFocused) return
    // We only want to validate if the user changed something,
    // but not if reset by a successful stake
    // We're strictly not validating when focus changes as it
    // causes the form to validate when we autofocus on mount
    // or when stake modal closes and reset the form
    validate()
  }, [values.amount])

  const isDisabled = useMemo(() => {
    if (strideAccount == null || selectedAccount == null) return true
    if (eligibleAirdropsError) return true
    return Boolean(errors.amount)
  }, [strideAccount, selectedAccount, eligibleAirdropsError, errors])

  const isTransferringAboveRecommendedBalance = useMemo(() => {
    // If user is staking more than their total balance, we'll let the validation take care of it.
    if (Number(values.amount) > totalBalance) {
      return false
    }

    return Number(values.amount) > recommendedMaxAmount.toNumber()
  }, [values, totalBalance, recommendedMaxAmount])

  const stakedDenom = `st${denom}`

  const isUsingEvmos = denom === EVMOS_CHAIN_INFO.stakeCurrency.coinDenom

  return (
    <>
      <form onSubmit={onSubmit(handleSubmit)}>
        <Text align="center" color="gray">
          Stake your {denom} tokens in exchange for {stakedDenom} which you can deploy around the ecosystem. You can
          liquid stake half of your balance, if you're going to LP.
        </Text>

        <Space h="lg" />

        <Box>
          <Text inline sx={(t) => ({ color: t.colors.gray[7] })}>
            Amount to stake:
          </Text>

          <Space h="xs" />

          <Text weight={700} size="xl" inline sx={(t) => ({ color: t.colors.gray[9] })}>
            {denom}
          </Text>
        </Box>

        <Space h="sm" />

        <TextInput
          ref={amountInputRef}
          required
          placeholder="0"
          size="xl"
          sx={{ input: { fontWeight: 'bold', textAlign: 'right' } }}
          onFocus={handleFocus}
          onBlur={handleBlur}
          {...getInputProps('amount')}
        />

        {/* This is intentional. Both conditions use the same UI, but we only want one to appear at a time. Recommended balance warning takes precedence if it exists. */}

        {(isTransferringAboveRecommendedBalance || isUsingEvmos) && (
          <>
            <Space h="xs" />

            <Alert color="yellow">
              <Text sx={(t) => ({ color: t.colors.gray[7] })}>
                {isTransferringAboveRecommendedBalance ? (
                  <span>
                    It looks like you're transferring close to your full balance. We recommend you leave at least 0.1{' '}
                    {denom} in your wallet to cover the gas fees associated with liquid staking. The transaction may
                    fail if you run out of gas.
                  </span>
                ) : (
                  <span>
                    Please note, Evmos currently does not support transactions with Ledger. This is expected to be
                    resolved with Evmos' v12 software upgrade.
                  </span>
                )}
              </Text>
            </Alert>
          </>
        )}

        {Boolean(strideAccount) && (
          <>
            <Space h="xs" />

            <Group position="apart" align="center">
              <Group spacing={0}>
                <Text sx={(t) => ({ color: t.colors.gray[7] })}>Available in wallet:</Text>
                &nbsp;
                <TextLoader loading={isFetchingSelectedAccount}>
                  <>
                    {new BigNumber(totalBalance).decimalPlaces(5).toString()} {denom}
                  </>
                </TextLoader>
              </Group>

              <Group spacing="sm">
                <UnstyledButton onClick={handleClickHalf}>
                  <Text color="pink">Add half</Text>
                </UnstyledButton>

                <UnstyledButton onClick={handleClickMax}>
                  <Text color="pink">Add max</Text>
                </UnstyledButton>
              </Group>
            </Group>
          </>
        )}

        <Space h="lg" />

        <Divider />

        <Space h="lg" />

        <Group position="apart" align="center">
          <Box>
            <Text inline sx={(t) => ({ color: t.colors.gray[7] })}>
              What you'll get:
            </Text>

            <Space h="xs" />

            <Text weight={700} size="xl" inline sx={(t) => ({ color: t.colors.gray[9] })}>
              {stakedDenom}
            </Text>
          </Box>

          <Text
            weight={700}
            size="xl"
            sx={(t) => ({ paddingRight: t.other.stakeForm.amountPaddingRight, color: t.colors.gray[9] })}
            inline>
            <StakeEstimate amount={Number(values.amount)} mode="stake" />
          </Text>
        </Group>

        <Space h="lg" />

        <Tooltip
          label="We have trouble calculating your gas amount. Please refresh the page."
          disabled={!eligibleAirdropsError}
          sx={{ width: '100%' }}
          withinPortal>
          <Button
            type="submit"
            fullWidth
            size="lg"
            radius="md"
            disabled={isDisabled}
            loading={isEligibleAirdropsLoading}>
            Liquid stake
          </Button>
        </Tooltip>
      </form>

      <StakeModal amount={Number(values.amount)} isOpen={isOpen} onClose={handleClose} onSuccess={handleSuccess} />
    </>
  )
}

export { StakeForm }
