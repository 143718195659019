import React from 'react'
import { useAtom, useAtomValue } from 'jotai'
import {
  isIBCMetaData,
  isIBCLiquidityPoolMetaData,
  isIBCWithdrawStTokenMetaData,
  isRedemptionMetaData,
  isLiquidStakeMetaData,
  strideAccountAtom,
  selectedAccountAtom
} from '@/atoms/Wallet'
import { fatal } from '@/utils'
import { dismissedTransactionsAtom } from '../atoms'
import { IBCMetaDataCard } from './IBCMetaDataCard'
import { RedemptionMetaDataCard } from './RedemptionMetaDataCard'
import { LiquidStakeMetaDataCard } from './LiquidStakeMetaDataCard'
import { IBCLiquidityPoolMetaDataCard } from './IBCLiquidityPoolMetaDataCard'
import { IBCWithdrawStTokenMetaDataCard } from './IBCWithdrawStTokenMetaDataCard'
import { useTransactionHistoryQuery } from './queries'

const StakeTransactionHistory: React.FC = () => {
  const strideAccount = useAtomValue(strideAccountAtom)

  const selectedAccount = useAtomValue(selectedAccountAtom)

  const [dismissedTxs, setDismissedTxs] = useAtom(dismissedTransactionsAtom)

  const { data } = useTransactionHistoryQuery()

  const handleDismiss = (hash: string) => {
    setDismissedTxs((arr) => [...arr, hash])
  }

  if (!strideAccount || !selectedAccount) {
    return null
  }

  if (data == null) {
    return null
  }

  return (
    <>
      {data
        .filter((meta) => {
          if (isIBCMetaData(meta)) return !dismissedTxs.includes(meta.values.hash)
          if (isIBCLiquidityPoolMetaData(meta)) return !dismissedTxs.includes(meta.values.hash)
          if (isIBCWithdrawStTokenMetaData(meta)) return !dismissedTxs.includes(meta.values.hash)
          if (isRedemptionMetaData(meta)) return true
          if (isLiquidStakeMetaData(meta)) return !dismissedTxs.includes(meta.values.hash)
          return false
        })
        .map((meta) => {
          if (isIBCLiquidityPoolMetaData(meta)) {
            return <IBCLiquidityPoolMetaDataCard meta={meta} onDismiss={handleDismiss} key={meta.values.hash} />
          }

          if (isIBCWithdrawStTokenMetaData(meta)) {
            return <IBCWithdrawStTokenMetaDataCard meta={meta} onDismiss={handleDismiss} key={meta.values.hash} />
          }

          if (isIBCMetaData(meta)) {
            return <IBCMetaDataCard meta={meta} onDismiss={handleDismiss} key={meta.values.hash} />
          }

          if (isRedemptionMetaData(meta)) {
            return <RedemptionMetaDataCard meta={meta} key={meta.values.epochNumber} />
          }

          if (isLiquidStakeMetaData(meta)) {
            return <LiquidStakeMetaDataCard meta={meta} onDismiss={handleDismiss} key={meta.values.hash} />
          }

          throw fatal('Cannot render an invalid transaction')
        })}
    </>
  )
}

export { StakeTransactionHistory }
