import { selectedCoinDenomAtom } from '@/atoms/Wallet'
import { CollapsibleStatsItem, CollapsibleStatsItemAnchor, CollapsibleStatsList, Icon, TextLoader } from '@/components'
import { config, CHAIN_CONFIG } from '@/config'
import { Anchor, Box, Card, Space, Text, Title, Tooltip } from '@mantine/core'
import { useHostZoneQuery, useStakeStatsQuery } from '@/queries'
import { useOsmosisMarketPriceQuery } from './queries'
import { useAtom } from 'jotai'
import BigNumber from 'bignumber.js'
import React, { useMemo } from 'react'

const StakeStats: React.FC = () => {
  const [denom] = useAtom(selectedCoinDenomAtom)

  const { data: hostZone, isLoading: isHostZoneLoading, error: hostZoneError } = useHostZoneQuery()

  const { data: stakeStats, isLoading: isStakeStatsLoading, error: stakeStatsError } = useStakeStatsQuery()

  const {
    data: osmosisMarketPrice,
    isLoading: isOsmosisMarketPriceLoading,
    error: osmosisMarketPriceError
  } = useOsmosisMarketPriceQuery()

  const currentYield = useMemo(() => {
    if (stakeStats == null) return '0%'
    const { currentYield } = stakeStats[denom]
    return `${new BigNumber(currentYield).multipliedBy(100).decimalPlaces(2)}%`
  }, [denom, stakeStats])

  const strideYield = useMemo(() => {
    if (stakeStats == null) return '0%'
    const { strideYield } = stakeStats[denom]
    return `${new BigNumber(strideYield).multipliedBy(100).decimalPlaces(2)}%`
  }, [denom, stakeStats])

  const unbondingPeriod = useMemo(() => {
    if (stakeStats == null) return '0%'
    const { start, end, unit } = stakeStats[denom].unbondingPeriod
    return `${start}-${end} ${unit}`
  }, [denom, stakeStats])

  const stakedTokenValue = useMemo(() => {
    if (hostZone == null) return '0'
    return new BigNumber(hostZone.redemption_rate).decimalPlaces(3).toString()
  }, [hostZone])

  const osmosisMarketPriceValue = useMemo(() => {
    if (osmosisMarketPrice == null) return '0'
    return new BigNumber(osmosisMarketPrice.value).decimalPlaces(3).toString()
  }, [osmosisMarketPrice])

  const hasOsmosisPool = Boolean(CHAIN_CONFIG[denom].poolId)

  return (
    <>
      <Card p="md" withBorder>
        <Title order={5} sx={(t) => ({ color: t.colors.gray[9], fontWeight: 600 })}>
          About {denom} on Stride
        </Title>

        <CollapsibleStatsList>
          <CollapsibleStatsItem
            icon="trophy"
            label="Rewards"
            value={
              Boolean(stakeStatsError) ? (
                <Tooltip label="Unable to load stride yield at this time." withArrow>
                  <Icon name="warning" sx={(t) => ({ color: t.colors.yellow[6] })} />
                </Tooltip>
              ) : (
                <TextLoader loading={isStakeStatsLoading}>{strideYield}</TextLoader>
              )
            }
            description={
              <>
                Rewards on Stride are similar to native staking rewards. However, you can use your st{denom} throughout
                DeFi.
              </>
            }
            data={[
              {
                label: 'Typical yield',
                value: Boolean(stakeStatsError) ? (
                  <Tooltip label="Unable to load current yield at this time." withArrow>
                    <Icon name="warning" sx={(t) => ({ color: t.colors.yellow[6] })} />
                  </Tooltip>
                ) : (
                  <TextLoader loading={isStakeStatsLoading}>{currentYield}</TextLoader>
                )
              },
              {
                label: 'Stride yield (after fee)',
                value: Boolean(stakeStatsError) ? (
                  <Tooltip label="Unable to load stride yield at this time." withArrow>
                    <Icon name="warning" sx={(t) => ({ color: t.colors.yellow[6] })} />
                  </Tooltip>
                ) : (
                  <TextLoader loading={isStakeStatsLoading}>{strideYield}</TextLoader>
                )
              }
            ]}
          />

          <CollapsibleStatsItem
            icon="atom"
            label="Fees"
            value={<Text>Low</Text>}
            description="Stride's 10% fee is only applied to rewards you earn. The tokens you stake (aka principal) and transactions are fee-free!"
          />

          <CollapsibleStatsItem
            icon="arrowHorizontal"
            label="Unbonding"
            value={
              Boolean(stakeStatsError) ? (
                <Tooltip label="Unable to load unbonding period at this time." withArrow>
                  <Icon name="warning" sx={(t) => ({ color: t.colors.yellow[6] })} />
                </Tooltip>
              ) : (
                <TextLoader loading={isStakeStatsLoading}>{unbondingPeriod}*</TextLoader>
              )
            }
            description={
              <>
                Unstaking on Stride requires an unbonding period before you can withdraw your tokens. If you don't want
                to wait, you can sell st{denom} directly on an exchange like{' '}
                <CollapsibleStatsItemAnchor href={config.links.osmosis} target="_blank">
                  Osmosis
                </CollapsibleStatsItemAnchor>
                . In rare cases, you may need to wait slightly longer for unbondings to process, learn more in{' '}
                <CollapsibleStatsItemAnchor href={config.links.docs} target="_blank">
                  our docs
                </CollapsibleStatsItemAnchor>
                .
              </>
            }
          />

          <CollapsibleStatsItem
            icon="upwardArrow"
            label={`Value of 1 st${denom}`}
            value={
              Boolean(hostZoneError) ? (
                <Tooltip label="Unable to load redemption rate at this time." withArrow>
                  <Icon name="warning" sx={(t) => ({ color: t.colors.yellow[6] })} />
                </Tooltip>
              ) : (
                <TextLoader loading={isHostZoneLoading}>
                  1 st{denom} = {stakedTokenValue} {denom}
                </TextLoader>
              )
            }
            description={
              <>
                <Box>
                  The value of 1 st{denom} if redeemed through the Stride protocol redemption rate grows predictably as
                  staking rewards accrue.
                </Box>
                <Space h="md" />
                <Box>The market price of 1 st{denom} on exchanges fluctuates based on supply and demand.</Box>
              </>
            }
            data={[
              {
                label: 'Protocol redemption rate',

                value: Boolean(hostZoneError) ? (
                  <Tooltip label="Unable to load redemption rate at this time." withArrow>
                    <Icon name="warning" sx={(t) => ({ color: t.colors.yellow[6] })} />
                  </Tooltip>
                ) : (
                  <TextLoader loading={isHostZoneLoading}>
                    1 st{denom} = {stakedTokenValue} {denom}
                  </TextLoader>
                )
              },
              {
                label: 'Osmosis market rate',

                value: hasOsmosisPool ? (
                  Boolean(osmosisMarketPriceError) ? (
                    <Tooltip label="Unable to load osmosis market price at this time." withArrow>
                      <Icon name="warning" sx={(t) => ({ color: t.colors.yellow[6] })} />
                    </Tooltip>
                  ) : (
                    <TextLoader loading={isOsmosisMarketPriceLoading}>
                      1 st{denom} = {osmosisMarketPriceValue} {denom}
                    </TextLoader>
                  )
                ) : (
                  <Text>None</Text>
                )
              }
            ]}
          />
        </CollapsibleStatsList>

        <Space h="xs" />

        <Card.Section
          p="md"
          pt="sm"
          sx={(t) => ({ background: t.colors.gray[0], paddingBottom: t.other.stakeStatsCard.paddingBottom })}>
          <Text sx={(t) => ({ color: t.colors.gray[7] })}>
            Want to learn more about rewards, fees and unbonding on Stride? Check out the{' '}
            <Anchor href={config.links.docs} target="_blank" underline sx={{ color: 'currentColor' }}>
              Stride Docs
            </Anchor>
          </Text>
        </Card.Section>
      </Card>
    </>
  )
}

export { StakeStats }
