import React, { useMemo } from 'react'
import BigNumber from 'bignumber.js'
import { Anchor, Button, Space } from '@mantine/core'
import { TransactionCard, TransactionCardStatus } from '@/components'
import { config, OSMO_CHAIN_INFO } from '@/config'
import { IBCLiquidityPoolMetaData } from '@/atoms/Wallet'
import { useIbcStatusQuery } from './queries'

interface IBCLiquidityPoolMetaDataCardProps {
  meta: IBCLiquidityPoolMetaData
  onDismiss: (id: string) => void
}

const IBCLiquidityPoolMetaDataCard: React.FC<IBCLiquidityPoolMetaDataCardProps> = ({ meta, onDismiss }) => {
  const { data, error } = useIbcStatusQuery({ transaction: meta })

  const status = useMemo(() => {
    if (error) return 'checking-failed'
    if (data == null) return 'checking'
    if (data.status === 'return-later') return 'pending'
    if (data.status === 'timeout') return 'error'
    return data.status
  }, [data, error])

  const formattedAmount = `${new BigNumber(meta.values.amount).decimalPlaces(5).toString()} ${meta.values.denom}`

  const titles: Record<TransactionCardStatus, () => string> = {
    checking: () => `Transfer of ${formattedAmount} to Osmosis`,
    'checking-failed': () => `Transfer of ${formattedAmount} to Osmosis`,
    pending: () => `Transferring ${formattedAmount} to Osmosis`,
    error: () => `Error transferring ${formattedAmount}`,
    complete: () => `${formattedAmount} transferred to Osmosis`
  }

  const description: Record<TransactionCardStatus, () => React.ReactNode> = {
    checking: () => 'Checking the status of your IBC transfer.',
    'checking-failed': () => 'We were unable to check the status of this IBC transaction. Please refresh the page.',
    pending: () =>
      'This could take 30 seconds or longer if the network is congested. If you exit Stride, this status may not be visible when you return, but the transfer will continue. Once the transfer is complete, you will need to return to initiate the staking process.',
    complete: () => (
      <>
        {formattedAmount} has been successfully transferred from Stride to your wallet on {OSMO_CHAIN_INFO.chainName}.
        The tokens are visible at your{' '}
        <Anchor href={config.links.osmosisAssets} target="_blank" underline sx={{ color: 'inherit' }}>
          {OSMO_CHAIN_INFO.chainName}
        </Anchor>{' '}
        address.
      </>
    ),
    error: () =>
      'This transfer could not be completed. Your tokens have not been moved to Osmosis. You can try again if you haven’t already.'
  }

  const isPersisted = status === 'complete' && Boolean(meta.values.hash) && meta.minimizeId == null

  return (
    <TransactionCard
      status={status}
      title={titles[status]()}
      onDismiss={() => onDismiss(meta.values.hash)}
      description={description[status]()}>
      {isPersisted && (
        <>
          <Space h="md" />

          <Button
            component="a"
            target="_blank"
            href={`${config.network.mintscanTxUrl}/${meta.values.hash}`}
            variant="outline"
            color="dark">
            View details
          </Button>
        </>
      )}
    </TransactionCard>
  )
}

export { IBCLiquidityPoolMetaDataCard }
