import { useMutation, useQueryClient } from 'react-query'
import { useAtom } from 'jotai'
import { assertIsDeliverTxSuccess } from '@cosmjs/stargate'
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx'
import { queryKeys } from '@/query-keys'
import { fatal, disregard } from '@/utils'
import { notify } from '@/atoms/Notifications'
import {
  strideAccountAtom,
  selectedAccountAtom,
  traceIbcStatus,
  useUpdateAccountBalances,
  IBCTransferStatus
} from '@/atoms/Wallet'
import { MutationParameters } from './types'

const useBroadcastWithdrawStTokenMutation = ({ encodedRef, transactionRef }: MutationParameters) => {
  const client = useQueryClient()

  const [strideAccount] = useAtom(strideAccountAtom)

  const [selectedAccount] = useAtom(selectedAccountAtom)

  const { updateAccountBalances } = useUpdateAccountBalances()

  const handleMutation = async (): Promise<{ status: IBCTransferStatus }> => {
    if (!strideAccount || !selectedAccount) {
      throw fatal('You are unable to send token without connecting your wallet.')
    }

    if (!encodedRef.current) {
      throw fatal('Unable to broadcast empty signed transaction.')
    }

    const bytes = TxRaw.encode(encodedRef.current).finish()

    // We'll use account.stargate instead of account.client as stridejs.getSigningStridelabsClient
    // oes not contain default amino types for messages such as TX_MSG.MsgTransfer.
    // Let's get rid of this in the future once we've fixed this directly from stride.js
    // @TODO https://github.com/Stride-Labs/interface/issues/81
    transactionRef.current = await strideAccount.stargate.broadcastTx(bytes)

    await client.invalidateQueries(queryKeys.transactionHistoryBase)

    assertIsDeliverTxSuccess(transactionRef.current)

    const status = await traceIbcStatus({ account: strideAccount, tx: transactionRef.current })

    await client.invalidateQueries(
      queryKeys.transactionHistoryIbcStatusByHash({ hash: transactionRef.current.transactionHash })
    )

    try {
      await updateAccountBalances(strideAccount)
      await updateAccountBalances(selectedAccount)
    } catch (e) {
      notify.error(`An error occured while fetching your updated account balance. Please refresh the page.`)
      disregard(e)
    }

    // @TODO: Handle if ibc status === 'timeout'. We likely need a flow for this.
    return { status }
  }

  return useMutation(handleMutation)
}

export { useBroadcastWithdrawStTokenMutation }
