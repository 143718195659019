import { format } from 'date-fns'
import { convertMicroDenomToDenom, LiquidStakeMetaData, MsgLiquidStake } from '@/atoms/Wallet'
import { MetaDataFactoryParameters } from './types'

const createLiquidStakeMetaData = ({
  response,
  message,
  selectedAccount
}: MetaDataFactoryParameters<MsgLiquidStake>) => {
  const meta: LiquidStakeMetaData = {
    type: 'stake',
    values: {
      hash: response.txhash,
      // Let's add a new property called stakedAmount so we're much more accurate
      // instead of trying to calculate it using the current redemption rate which
      // may be different than when the actual stake occurred.
      amount: convertMicroDenomToDenom(String(message.amount), selectedAccount.currency.coinMinimalDenom),
      // We are hard-coding this so we don't have to write type-guards.
      // @TODO In the future, maybe on v2, let's remove this as we don't seem to need this to be strictly typed.
      // This assumes that all of the transactions are from Stride
      denom: selectedAccount.currency.coinDenom,
      // We are hard-coding this so we don't have to write type-guards.
      // @TODO In the future, maybe on v2, let's remove this as we don't seem to need this to be strictly typed.
      // This assumes that all of the transactions are from Stride
      minimalDenom: selectedAccount.currency.coinMinimalDenom,
      status: 'complete'
    },
    minimizeId: null,
    // @TODO: Refactor this to be unformatted and let StakeTransactionList do its job
    startedAt: format(new Date(response.timestamp), 'MMMM d, yyyy h:mm O'),
    estimatedAt: null,
    completedAt: null
  }

  return meta
}

export { createLiquidStakeMetaData }
