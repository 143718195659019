import React, { useEffect } from 'react'
import { useAtomValue } from 'jotai'
import { Button, Space, Skeleton, useMantineTheme } from '@mantine/core'
import { StepperModalContent, useStepperContext } from '@/components'
import { selectedAccountAtom } from '@/atoms/Wallet'
import { fatal } from '@/utils'
import { useLiquidStakeTransactionAmountQuery } from './queries'
import { useStakeLiquidityPool } from './StakeLiquidityPoolContext'

const StakeLiquidityPoolStepOne: React.FC = () => {
  const { signStakeToLiquidityPool, signStakeToLiquidityPoolError } = useStakeLiquidityPool()

  const { start, nextStep, forceClose } = useStepperContext()

  const selectedAccount = useAtomValue(selectedAccountAtom)

  const {
    data: stakeTransactionAmount,
    isLoading: isStakeTransactionAmountLoading,
    error: stakeTransactionAmountError,
    refetch: refetchStakeTransaction
  } = useLiquidStakeTransactionAmountQuery()

  const theme = useMantineTheme()

  const handleStart = async () => {
    start()
    await signStakeToLiquidityPool()
    nextStep()
  }

  useEffect(() => {
    if (stakeTransactionAmount == null) return
    handleStart()
  }, [stakeTransactionAmount])

  const handleRetry = () => {
    handleStart()
  }

  const handleRefetch = () => {
    refetchStakeTransaction()
  }

  if (!selectedAccount) {
    throw fatal('Unable to initiate ibc transfer for osmosis liquidity pools while disconnected.')
  }

  if (isStakeTransactionAmountLoading) {
    return (
      <>
        <Skeleton width={120} height={theme.fontSizes.md} />
        <Space h="sm" />
        <Skeleton width={240} height={theme.fontSizes.sm} />
        <Space h="xs" />
        <Skeleton width={80} height={theme.fontSizes.sm} />
      </>
    )
  }

  if (stakeTransactionAmountError) {
    return (
      <StepperModalContent
        // User must fund the entire transaction with IBC and has no tokens on Stride
        title={`Unable to proceed`}
        description="Failed to fetch liquid stake transaction."
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Back to Stride
            </Button>

            <Button color="dark" variant="outline" onClick={handleRefetch}>
              Try again
            </Button>
          </>
        }
      />
    )
  }

  if (signStakeToLiquidityPoolError) {
    return (
      <StepperModalContent
        // User must fund the entire transaction with IBC and has no tokens on Stride
        title={`Transaction error`}
        description="This transfer could not be completed. Your tokens have not been moved."
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Back to Stride
            </Button>

            <Button color="dark" variant="outline" onClick={handleRetry}>
              Try again
            </Button>
          </>
        }
      />
    )
  }

  return (
    <StepperModalContent
      // User must fund the entire transaction with IBC and has no tokens on Stride
      title={`Approve the transaction in your  wallet to continue`}
      description={`This will start the transfer of your st${selectedAccount.currency.coinDenom} tokens to Osmosis.`}
    />
  )
}

export { StakeLiquidityPoolStepOne }
