import React, { useMemo } from 'react'
import { Loader } from '@mantine/core'
import { useHostZoneQuery } from '@/queries'
import BigNumber from 'bignumber.js'

interface StakeEstimateProps {
  amount: number
  format?: (total: number) => string | number
  mode: 'stake' | 'unstake'
}

const defaultFormat = (n: number) => new BigNumber(n).decimalPlaces(2).toString()

const StakeEstimate: React.FC<StakeEstimateProps> = ({ amount, mode, format = defaultFormat }) => {
  const { data: hostZone, isLoading } = useHostZoneQuery()

  const estimate = useMemo(() => {
    if (!amount || !hostZone?.redemption_rate) {
      return 0
    }

    const value =
      mode === 'stake'
        ? new BigNumber(amount).dividedBy(hostZone.redemption_rate)
        : new BigNumber(amount).multipliedBy(hostZone.redemption_rate)

    return format(value.toNumber())
  }, [hostZone, amount, mode])

  if (isLoading) {
    return <Loader variant="dots" color="dark" size="xs" />
  }

  return <span>{estimate}</span>
}

export { StakeEstimate }
