import React, { useMemo } from 'react'
import BigNumber from 'bignumber.js'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { Group, Tooltip } from '@mantine/core'
import { convertMicroDenomToDenom, selectedAccountDenomAtom } from '@/atoms/Wallet'
import { liquidityPoolModalIsOpenAtom, liquidityPoolTransactionHashAtom } from '@/page-components/Stake/atoms'
import { StepperProvider, StepperModal, useStepper, Icon, InlineLoader } from '@/components'
import { useLiquidStakeTransactionAmountQuery } from './queries'
import { useStakeLiquidityPool } from './StakeLiquidityPoolContext'
import { StakeLiquidityPoolStepOne } from './StakeLiquidityPoolStepOne'
import { StakeLiquidityPoolStepTwo } from './StakeLiquidityPoolStepTwo'
import { StakeLiquidityPoolStepThree } from './StakeLiquidityPoolStepThree'

const StakeLiquidityPoolModal: React.FC = () => {
  const {
    resetLiquidityPoolState,
    signStakeToLiquidityPoolError,
    isBroadcastingStakeToLiquidityPool,
    broadcastStakeToLiquidityPoolError,
    ibcStatusError,
    isIbcStatusLoading
  } = useStakeLiquidityPool()

  const { data: stakeTransactionAmount, error: stakeTransactionAmountError } = useLiquidStakeTransactionAmountQuery()

  const [isOpen, setIsOpen] = useAtom(liquidityPoolModalIsOpenAtom)

  const setHash = useSetAtom(liquidityPoolTransactionHashAtom)

  const denom = useAtomValue(selectedAccountDenomAtom)

  const steps = useMemo(() => {
    return ['Approve transfer in wallet', `Transfer st${denom} to Osmosis`, 'Add to LP in Osmosis']
  }, [denom])

  const handleClose = () => {
    setIsOpen(false)
    setHash(null)
    resetLiquidityPoolState()
  }

  const stepper = useStepper({
    steps,
    warning: isIbcStatusLoading,
    error: Boolean(signStakeToLiquidityPoolError || broadcastStakeToLiquidityPoolError || ibcStatusError),
    isOpen,
    isLoading: isBroadcastingStakeToLiquidityPool || isIbcStatusLoading,
    isComplete: false,
    onClose: handleClose
  })

  const renderAmount = () => {
    if (stakeTransactionAmountError) {
      return (
        <Group spacing="xs">
          <Tooltip label="Unable to load staked amount at this time." withArrow position="right">
            <Icon name="warning" sx={(t) => ({ color: t.colors.yellow[6] })} />
          </Tooltip>
          st{denom}
        </Group>
      )
    }

    if (stakeTransactionAmount == null) {
      return (
        <Group spacing="xs">
          <InlineLoader />
          <span>st{denom}</span>
        </Group>
      )
    }

    // The query returns a micro denom unlike the data from selectedAccountAtom,
    // strideAcountAtom, osmosisAccountAtom which are handled by useConnectWallet.
    const amount = convertMicroDenomToDenom(stakeTransactionAmount.amount)

    return (
      <span>
        {new BigNumber(amount).decimalPlaces(5).toString()} st{denom}
      </span>
    )
  }

  return (
    <StepperProvider {...stepper}>
      <StepperModal title="Add to Liquidity Pool" amount={renderAmount()}>
        <StakeLiquidityPoolStepOne />
        <StakeLiquidityPoolStepTwo />
        <StakeLiquidityPoolStepThree />
      </StepperModal>
    </StepperProvider>
  )
}

export { StakeLiquidityPoolModal }
