import { notify } from '@/atoms/Notifications'
import {
  selectedAccountDenomAtom,
  useTraceIbcTransfer,
  useWalletConnect,
  InsufficientTokensToTransferError
} from '@/atoms/Wallet'
import { StepperModalContent, useStepperContext } from '@/components'
import { useMount } from '@/hooks'
import { fatal } from '@/utils'
import { Button } from '@mantine/core'
import { useAtom } from 'jotai'
import React from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { queryKeys } from '@/query-keys'
import { ibcTransferTxAtom, ibcTransferTxStatusAtom, sendTokenTxRawAtom } from './atoms'

interface StakeModalStepTwoProps {
  amount: number
}

const StakeModalStepTwo: React.FC<StakeModalStepTwoProps> = () => {
  const client = useQueryClient()

  const { broadcastSendToken, isBroadcastingSendToken, broadcastSendTokenError } = useWalletConnect()

  const [denom] = useAtom(selectedAccountDenomAtom)

  const [sendTokenTxRaw] = useAtom(sendTokenTxRawAtom)

  // @TODO: Let's re-implement this the same way we implemented
  // useTraceIbcTransfer in Osmosis Liquidity Pools feature.
  const [tx, setTx] = useAtom(ibcTransferTxAtom)

  const [txStatus, setTxStatus] = useAtom(ibcTransferTxStatusAtom)

  const { nextStep, forceClose, handleClose } = useStepperContext()

  const { traceIbcStatus } = useTraceIbcTransfer()

  const handleStep = async () => {
    if (sendTokenTxRaw == null) {
      throw fatal('Send token transaction was being broadcasted to the blockchain without being signed.')
    }

    const { tx, status } = await broadcastSendToken({ raw: sendTokenTxRaw, mode: 'deposit' })

    setTxStatus(status)

    setTx(tx)

    // We'll bail out and let the traceIbcStatus hook handle this
    if (status === 'return-later') {
      return
    }

    nextStep()
  }

  const handleReturnLater = async () => {
    if (tx == null) {
      throw fatal('IBC transfer is being traced while tx state is null.')
    }

    await traceIbcStatus({ tx, mode: 'deposit' })

    await client.invalidateQueries(queryKeys.transactionHistoryIbcStatusByHash({ hash: tx.transactionHash }))
  }

  const handleReturnLaterSuccess = async () => {
    setTxStatus('complete')
    nextStep()
  }

  const handleCloseCallback = () => {
    // If it was closed after the broadcast, we don't need to prepend.
    if (!isBroadcastingSendToken) {
      return
    }

    notify.progress('Transaction minimized', "We'll let you know when the transfer completes.")
  }

  useMount(() => {
    handleStep()
  })

  useQuery(['stake-modal', 'ibc-trace-status', tx, txStatus], handleReturnLater, {
    enabled: Boolean(tx) && txStatus === 'return-later',
    onSuccess: handleReturnLaterSuccess
  })

  handleClose(handleCloseCallback)

  const handleRetryBroadcastSendToken = async () => {
    handleStep()
  }

  if (txStatus === 'return-later') {
    return (
      <StepperModalContent
        title="This transaction is taking longer than usual"
        description={`If you don’t want to wait here, you can close this dialog. Your transaction will continue and you’ll be able to see status on the Stride app page.`}
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Back to Stride
            </Button>
          </>
        }
      />
    )
  }

  if (broadcastSendTokenError instanceof InsufficientTokensToTransferError) {
    return (
      <StepperModalContent
        title="Transaction error"
        description={`You have insufficient tokens to complete the transfer. Decrease the stake amount to take into account for the gas fees.`}
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Back to Stride
            </Button>
            <Button color="dark" variant="outline" onClick={handleRetryBroadcastSendToken}>
              Try again
            </Button>
          </>
        }
      />
    )
  }

  if (broadcastSendTokenError) {
    return (
      <StepperModalContent
        title="Transaction error"
        description={`This transfer could not be completed. Your tokens have not been moved.`}
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Back to Stride
            </Button>
            <Button color="dark" variant="outline" onClick={handleRetryBroadcastSendToken}>
              Try again
            </Button>
          </>
        }
      />
    )
  }

  return (
    <StepperModalContent
      title={`Transferring your ${denom} to the Stride app...`}
      description="This could take 30 seconds or longer if the network is congested. If you exit Stride, this status may not be visible when you return, but the transfer will continue. Once the transfer is complete, you will need to return to initiate the staking process."
    />
  )
}

export { StakeModalStepTwo }
