import React from 'react'
import { useAtom } from 'jotai'
import { Button } from '@mantine/core'
import { useMount } from '@/hooks'
import { fatal } from '@/utils'
import { StepperModalContent, useStepperContext } from '@/components'
import { selectedAccountDenomAtom, useWalletConnect } from '@/atoms/Wallet'
import { notify } from '@/atoms/Notifications'
import { claimMetaDataAtom, isUnstakingModalOpenAtom } from '@/page-components/Stake/atoms'

const UnstakeModalStepFour: React.FC = () => {
  const { claimToken, isClaimingToken, claimTokenError } = useWalletConnect()

  const [denom] = useAtom(selectedAccountDenomAtom)

  const [isUnstakingModalOpen] = useAtom(isUnstakingModalOpenAtom)

  const [claimMetaData] = useAtom(claimMetaDataAtom)

  // const stakedDenom = `st${denom}`

  const { start, forceClose, close, handleClose, complete } = useStepperContext()

  if (!claimMetaData) {
    throw fatal('Cannot claim without selecting a redemption record')
  }

  const handleStep = async () => {
    start()

    try {
      await claimToken({ epoch: claimMetaData.values.epochNumber })
    } catch (e) {
      if (!isUnstakingModalOpen) {
        notify.error('Claim resulted to an error. Please try again.')
      }

      return
    }

    if (isUnstakingModalOpen) {
      complete()
    } else {
      notify.success('Claim completed successfully.')
    }
  }

  const handleCloseCallback = () => {
    // If the broadcast completed, it doesn't matter
    if (!isClaimingToken) {
      return
    }

    notify.progress('Transaction minimized', "We'll let you know when the claim completes.")
  }

  useMount(() => {
    handleStep()
  })

  handleClose(handleCloseCallback)

  const handleRetrySignStakeTokens = () => {
    handleStep()
  }

  if (claimTokenError) {
    return (
      <StepperModalContent
        title="Transaction error"
        description="Your tokens couldn’t be claimed at this time. Try again in a few minutes."
        actions={
          <>
            <Button variant="outline" color="dark" onClick={forceClose}>
              Back to Stride
            </Button>

            <Button color="dark" onClick={handleRetrySignStakeTokens}>
              Try again
            </Button>
          </>
        }
      />
    )
  }

  if (isClaimingToken) {
    return (
      <StepperModalContent
        title="Approve transaction in your wallet to claim your tokens"
        description={`This will transfer ${claimMetaData.values.amount} ${denom} back to your wallet and the unstaking process will be complete.`}
      />
    )
  }

  return (
    <StepperModalContent
      title="Claim successful!"
      description={`You’ve successfully unstaked ${claimMetaData.values.amount} ${denom}`}
      // @TODO: Let's add this back once we have the correct value for 33.289 - this will be calculated with claimMetaData.values.amount * RedemptionRate
      // which we already have on `StakeEstimate` component
      // description={`You’ve successfully unstaked ${claimMetaData.values.amount} ${stakedDenom} and 33.289 ${denom} has been added to your wallet`}
      actions={<Button onClick={close}>Done</Button>}
    />
  )
}

export { UnstakeModalStepFour }
