import type { MsgTransfer } from 'cosmjs-types/ibc/applications/transfer/v1/tx'
import { fatal } from '@/utils'
import { convertMicroDenomToDenom, getHashedSendPacketAttributes, IBCLiquidityPoolMetaData } from '@/atoms/Wallet'
import { format } from 'date-fns'
import { MetaDataFactoryParameters } from './types'

const createIbcLiquidityPoolMetaData = ({
  response,
  message,
  selectedAccount
}: MetaDataFactoryParameters<MsgTransfer>) => {
  if (!message.token) {
    // We're sure that token exists because we validate it from  the queryPendingTxs fn
    throw fatal(`Transaction ${response.txhash} is a MsgTransfer message but does not contain message.token.`)
  }

  const packets = getHashedSendPacketAttributes(response.events)

  const meta: IBCLiquidityPoolMetaData = {
    type: 'ibc-lp',
    values: {
      hash: response.txhash,
      sequence: packets?.packet_sequence ?? '',
      dstChannel: packets?.packet_dst_channel ?? '',
      amount: convertMicroDenomToDenom(message.token.amount, selectedAccount.currency.coinMinimalDenom),
      sender: message.sender,
      receiver: message.receiver,
      // We are hard-coding this so we don't have to write type-guards.
      // @TODO In the future, maybe on v2, let's remove this as we don't seem to need this to be strictly typed.
      // This assumes that all of the transactions are from Stride.
      // The fetch method takes care of filtering out ibc transfers that are likely have been made elsewhere.
      // (i.e. Sending STRD to / from Osmosis through the Keplr extensionn
      denom: `st${selectedAccount.currency.coinDenom}`,
      // We are hard-coding this so we don't have to write type-guards.
      // @TODO In the future, maybe on v2, let's remove this as we don't seem to need this to be strictly typed.
      // This assumes that all of the transactions are from Stride.
      // The fetch method takes care of filtering out ibc transfers that are likely have been made elsewhere.
      // (i.e. Sending STRD to / from Osmosis through the Keplr extension)
      minimalDenom: `st${selectedAccount.currency.coinMinimalDenom}`,
      // @TODO: Let's update IBCTranferStatus type 'timeout' to 'error'
      // Remove this property as we calculate this through a separate request
      status: 'complete'
    },
    minimizeId: null,
    // @TODO: Refactor this to be unformatted and let StakeTransactionList do its job
    startedAt: format(new Date(response.timestamp), 'MMMM d, yyyy h:mm O'),
    estimatedAt: null,
    // @TODO: Refactor this to be unformatted and let StakeTransactionList do its job
    completedAt: null
  }

  return meta
}

export { createIbcLiquidityPoolMetaData }
