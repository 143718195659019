// @TODO: Let's refactor this entire file to use contexts instead
import { atom } from 'jotai'
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx'
import { DeliverTxResponse } from '@cosmjs/stargate'
import { IBCTransferStatus, TxRawEip712 } from '@/atoms/Wallet'

const sendTokenTxRawAtom = atom<TxRaw | TxRawEip712 | null>(null)

const stakeTokenTxRawAtom = atom<TxRaw | null>(null)

const isIbcTransferSkippedAtom = atom<boolean>(false)

// We'll store the correct amount the user is sending.
// This happens when user clicks "apply stride balance" where we ibc transfer (amount - stride.atom)
// This is used so we can display the correct amount when user minimized the transaction.
const ibcTransferAmountAtom = atom<number>(0)

// Used to store the tx details for return-later + showing correct StepperModal state
const ibcTransferTxAtom = atom<DeliverTxResponse | null>(null)

// Used to store the tx details for return-later + showing correct StepperModal state
const ibcTransferTxStatusAtom = atom<IBCTransferStatus>('pending')

export {
  sendTokenTxRawAtom,
  stakeTokenTxRawAtom,
  isIbcTransferSkippedAtom,
  ibcTransferAmountAtom,
  ibcTransferTxAtom,
  ibcTransferTxStatusAtom
}
