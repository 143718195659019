import { MsgLiquidStake } from '@/atoms/Wallet'
import { MetaDataHandlerParameters } from './types'

const handleLiquidStake = ({ message, selectedAccount }: MetaDataHandlerParameters<MsgLiquidStake>): boolean => {
  // We'll exclude liquid stake tx that did not come from the selected chain.
  // @NOTE: Look into this once we implement Stride-to-Stride staking.
  // @TODO: https://stridelabs.atlassian.net/jira/software/projects/IN/boards/5?selectedIssue=IN-81
  return message.host_denom === selectedAccount.currency.coinMinimalDenom
}

export { handleLiquidStake }
