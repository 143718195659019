import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { RedemptionMetaData } from '@/atoms/Wallet'

const claimMetaDataAtom = atom<RedemptionMetaData | null>(null)

const isUnstakingModalOpenAtom = atom(false)

const unstakeAmountAtom = atom<number>(0)

const amountWithdrawModalAtom = atom<number>(0)

const liquidityPoolModalIsOpenAtom = atom<boolean>(false)

const liquidityPoolTransactionHashAtom = atom<string | null>(null)

const withdrawStTokenModalIsOpenAtom = atom<boolean>(false)

const withdrawStTokenAmountAtom = atom<number>(0)

// Dismissed transactions persisted on local storage
// For any dismiss-related feature (if it's not being *removed* from the API),
// strictly use this instead of directly removing it from the pendingTxs array
// as this is persisted through out page reloads.
const dismissedTransactionsAtom = atomWithStorage<string[]>('stride.dismissed-tx', [])

export {
  claimMetaDataAtom,
  unstakeAmountAtom,
  isUnstakingModalOpenAtom,
  amountWithdrawModalAtom,
  liquidityPoolModalIsOpenAtom,
  withdrawStTokenModalIsOpenAtom,
  withdrawStTokenAmountAtom,
  liquidityPoolTransactionHashAtom,
  dismissedTransactionsAtom
}
