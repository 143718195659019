import { convertMicroDenomToDenom, formatEstimateRangeFromEpochNumber, RedemptionMetaData } from '@/atoms/Wallet'
import { RedemptionMetaDataFactoryParameters } from './types'

const createRedemptionMetaData = ({
  record,
  selectedAccount
}: RedemptionMetaDataFactoryParameters): RedemptionMetaData => {
  const meta: RedemptionMetaData = {
    type: 'unstaking',
    values: {
      amount: convertMicroDenomToDenom(record.amount, selectedAccount.currency.coinMinimalDenom),
      hostZoneId: record.host_zone_id,
      sender: record.sender,
      receiver: record.receiver,
      epochNumber: record.epoch_number,
      // We are hard-coding this so we don't have to write type-guards.
      // @TODO In the future, maybe on v2, let's remove this as we don't seem to need this to be strictly typed.
      denom: selectedAccount.currency.coinDenom,
      // We are hard-coding this so we don't have to write type-guards.
      // @TODO In the future, maybe on v2, let's remove this as we don't seem to need this to be strictly typed.
      minimalDenom: selectedAccount.currency.coinMinimalDenom,
      isClaimable: record.claim_is_pending
    },
    minimizeId: null,
    startedAt: null,
    estimatedAt: !record.claim_is_pending
      ? formatEstimateRangeFromEpochNumber(selectedAccount, record.epoch_number)
      : '',
    completedAt: null
  }

  return meta
}

export { createRedemptionMetaData }
