import React, { useRef } from 'react'
import { useAtom } from 'jotai'
import { useUpdateAtom } from 'jotai/utils'
import { Box, Button, Space } from '@mantine/core'
import { useMount } from '@/hooks'
import { STRIDE_CHAIN_INFO, AIRDROP_IDENTIFIERS, CHAIN_CONFIG } from '@/config'
import { stride } from 'stridejs'
import { useTopBanner } from '@/atoms/TopBanner'
import { useLiquidityPoolApyQuery } from './queries'
import {
  strideAccountAtom,
  selectedAccountAtom,
  selectedAccountDenomAtom,
  osmosisAccountAtom,
  useWalletConnect,
  convertMicroDenomToDenom
} from '@/atoms/Wallet'
import { notify } from '@/atoms/Notifications'
import { InlineLoader, StepperModalContent, useStepperContext } from '@/components'
import { disregard, fatal } from '@/utils'
import { useHostZoneQuery } from '@/queries'
import { stakeTokenTxRawAtom, ibcTransferTxAtom } from './atoms'
import {
  liquidityPoolModalIsOpenAtom,
  liquidityPoolTransactionHashAtom,
  dismissedTransactionsAtom
} from '@/page-components/Stake/atoms'
import BigNumber from 'bignumber.js'
import { DeliverTxResponse } from '@cosmjs/stargate'

interface StakeModalStepFourProps {
  amount: number
}

const StakeModalStepFour: React.FC<StakeModalStepFourProps> = ({ amount }) => {
  const { broadcastStakeToken, isBroadcastingStakeToken, isBroadcastStakeTokenSuccess, broadcastStakeTokenError } =
    useWalletConnect()

  const stakeTransactionRef = useRef<DeliverTxResponse>()

  const [denom] = useAtom(selectedAccountDenomAtom)

  const [selectedAccount] = useAtom(selectedAccountAtom)

  const [strideAccount] = useAtom(strideAccountAtom)

  const [osmosisAccount] = useAtom(osmosisAccountAtom)

  const [stakeTokenTxRaw] = useAtom(stakeTokenTxRawAtom)

  const [ibcTransferTx] = useAtom(ibcTransferTxAtom)

  const setLiquidityPoolModalIsOpen = useUpdateAtom(liquidityPoolModalIsOpenAtom)

  const setLiquidityPoolTransactionHash = useUpdateAtom(liquidityPoolTransactionHashAtom)

  const setDismissedTxs = useUpdateAtom(dismissedTransactionsAtom)

  const { setBanner } = useTopBanner()

  const { data: hostZone } = useHostZoneQuery()

  const { data: liquidityPoolApy, error: liquidityPoolApyError } = useLiquidityPoolApyQuery()

  const redemptionRate = Number(hostZone?.redemption_rate ?? 1)

  const stakedAmount = new BigNumber(amount).dividedBy(redemptionRate).decimalPlaces(3).toNumber()

  // May be a good idea to not format this and instead add a validation for max number of precisions
  const formattedAmount = `${new BigNumber(amount).decimalPlaces(3).toString()} ${denom}`

  const formattedStakedAmount = `${stakedAmount} st${denom}`

  const { complete, forceClose, close, handleClose } = useStepperContext()

  // We'll fetch if user has any claimable amount and prepare the data needed
  // so we can properly display a top-banner notification after liquid staking succeeds.
  const getClaimableAmountFromLiquidStakeTask = async (): Promise<number> => {
    if (!strideAccount) {
      throw fatal('You are unable to stake without connecting your wallet.')
    }

    const rpc = await stride.ClientFactory.createRPCQueryClient({
      rpcEndpoint: STRIDE_CHAIN_INFO.rpc
    })

    const data = await Promise.all(
      AIRDROP_IDENTIFIERS.map((identifier) => {
        return rpc.stride.claim.claimableForAction({
          airdropIdentifier: identifier,
          address: strideAccount.key.bech32Address,
          action: 1 // 1 Correponds to ACTION_LIQUID_STAKE
        })
      })
    )

    const total = data.reduce((total, claimable) => {
      const sum = claimable.coins.reduce((t, coin) => {
        return t.plus(coin.amount)
      }, new BigNumber(0))

      return total.plus(sum)
    }, new BigNumber(0))

    return new BigNumber(convertMicroDenomToDenom(total)).decimalPlaces(2).toNumber()
  }

  // @TODO: It may make more sense to move this entirely to the mutation.
  // If the user completed the airdrop task, we'll display a top-banner notification
  const handleClaimableAmountFromLiquidStakeTask = (amount: number) => {
    if (!strideAccount) {
      throw fatal('You are unable to stake without connecting your wallet.')
    }

    if (amount <= 0) {
      return
    }

    setBanner({
      icon: 'arrowHorizontal',
      text: (
        <>
          You completed a task and claimed{' '}
          <strong>
            {amount} {strideAccount.currency.coinDenom}
          </strong>
        </>
      ),
      url: '/airdrop'
    })
  }

  const handleStep = async () => {
    if (stakeTokenTxRaw == null) {
      throw fatal('Stake token transaction was being broadcasted to the blockchain without being signed.')
    }

    const claimableAmount = await getClaimableAmountFromLiquidStakeTask().catch((e) => {
      // If it fails, it doesn't matter in the UX.
      // Afterwards, we'll prevent top banner from being displayed + normalize type
      disregard(e)
      return 0
    })

    stakeTransactionRef.current = await broadcastStakeToken({ raw: stakeTokenTxRaw })

    if (ibcTransferTx?.transactionHash) {
      // For now, we'll place this here. Once we refactor the staking mutations out of the atoms folder
      // (colocate it into where it's being used), we best move this back into the mutation so we
      // have a much cleaner slate.
      setDismissedTxs((dismissedTxs) => [...dismissedTxs, ibcTransferTx.transactionHash])
    }

    complete()

    handleClaimableAmountFromLiquidStakeTask(claimableAmount)
  }

  const handleCloseCallback = () => {
    if (!selectedAccount) {
      throw fatal('Unable to stake while disconnected')
    }

    // It means it was closed after the broadcast
    if (!isBroadcastingStakeToken) {
      return
    }

    notify.progress('Transaction minimized', "We'll let you know when the stake completes.")
  }

  useMount(() => {
    handleStep()
  })

  handleClose(handleCloseCallback)

  const handleRetryBroadcastStakeToken = async () => {
    handleStep()
  }

  const handleAddToLiquidityPool = () => {
    if (stakeTransactionRef.current == null) {
      throw fatal('Unable to proceed to osmosis liquidity pools while stake transaction is null.')
    }

    setLiquidityPoolTransactionHash(stakeTransactionRef.current.transactionHash)
    close()
    setLiquidityPoolModalIsOpen(true)
  }

  if (selectedAccount == null) {
    throw fatal('Unable to complete staking while disconnected.')
  }

  if (broadcastStakeTokenError) {
    return (
      <StepperModalContent
        title="Transaction error"
        description={
          <>
            {' '}
            This transfer could not be completed. Your tokens are on Stride, but have not been staked. Please try again.
          </>
        }
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Back to Stride
            </Button>
            <Button color="dark" variant="outline" onClick={handleRetryBroadcastStakeToken}>
              Try again
            </Button>
          </>
        }
      />
    )
  }

  if (isBroadcastStakeTokenSuccess) {
    const isQualifiedForLiquidityPoolFlow = Boolean(
      osmosisAccount && CHAIN_CONFIG[selectedAccount.currency.coinDenom].poolId
    )

    return (
      <StepperModalContent
        title="Success!"
        description={
          <>
            <Box>
              You staked {formattedAmount} on Stride and {formattedStakedAmount} has been added to your wallet.
            </Box>

            {isQualifiedForLiquidityPoolFlow && (
              <>
                <Space h="xs" />

                {Boolean(liquidityPoolApyError) ? (
                  <Box>Do you want to add it to the liquidity pool on Osmosis to earn an extra APR?</Box>
                ) : (
                  <Box>
                    Do you want to add it to the liquidity pool on Osmosis to earn an{' '}
                    <strong>
                      extra{' '}
                      {liquidityPoolApy == null ? (
                        <InlineLoader />
                      ) : (
                        new BigNumber(liquidityPoolApy.apy).multipliedBy(100).decimalPlaces(2).toString()
                      )}
                      % APR
                    </strong>
                    ?
                  </Box>
                )}
              </>
            )}
          </>
        }
        actions={
          isQualifiedForLiquidityPoolFlow ? (
            <>
              <Button onClick={close} variant="outline" color="dark">
                No thanks
              </Button>

              <Button onClick={handleAddToLiquidityPool}>Add to LP</Button>
            </>
          ) : (
            <Button onClick={close} variant="outline" color="dark">
              Back to Stride
            </Button>
          )
        }
      />
    )
  }

  return (
    <StepperModalContent
      title={`Staking your ${denom}...`}
      description="Just a few seconds, unless the network is congested"
    />
  )
}

export { StakeModalStepFour }
