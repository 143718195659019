import React, { useMemo } from 'react'
import { useAtom } from 'jotai'
import { selectedAccountDenomAtom, useWalletConnect } from '@/atoms/Wallet'
import { StepperProvider, StepperModal, useStepper } from '@/components'
import { StakeModalStepOne } from './StakeModalStepOne'
import { StakeModalStepTwo } from './StakeModalStepTwo'
import { StakeModalStepThree } from './StakeModalStepThree'
import { StakeModalStepFour } from './StakeModalStepFour'
import { useLiquidityPoolApyQuery } from './queries'
import {
  sendTokenTxRawAtom,
  stakeTokenTxRawAtom,
  isIbcTransferSkippedAtom,
  ibcTransferAmountAtom,
  ibcTransferTxAtom,
  ibcTransferTxStatusAtom
} from './atoms'

interface StakeModalProps {
  amount: number
  isOpen: boolean
  onClose: () => void
  onSuccess: () => void
}

const StakeModal: React.FC<StakeModalProps> = ({ amount, isOpen, onClose, onSuccess }) => {
  const {
    isBroadcastingSendToken,
    isBroadcastingStakeToken,
    signSendTokenError,
    broadcastSendTokenError,
    signStakeTokenError,
    broadcastStakeTokenError,
    resetSignSendToken,
    resetBroadcastSendToken,
    resetSignStakeToken,
    resetBroadcastStakeToken
  } = useWalletConnect()

  const [denom] = useAtom(selectedAccountDenomAtom)

  const [, setSendTokenTxRaw] = useAtom(sendTokenTxRawAtom)

  const [, setStakeTokenTxRaw] = useAtom(stakeTokenTxRawAtom)

  const [, setIsIbcTransferSkipped] = useAtom(isIbcTransferSkippedAtom)

  const [, setIbcTransferAmountAtom] = useAtom(ibcTransferAmountAtom)

  const [, setIbcTransferTx] = useAtom(ibcTransferTxAtom)

  const [ibcTransferTxStatus, setIbcTransferTxStatus] = useAtom(ibcTransferTxStatusAtom)

  useLiquidityPoolApyQuery()

  const formattedAmount = `${amount} ${denom}`

  const steps = useMemo(() => {
    return [
      'Approve transfer in wallet',
      `Transfer ${denom} to Stride`,
      'Approve staking in wallet',
      `Stake ${denom}, get st${denom}`
    ]
  }, [denom])

  const handleClose = () => {
    onClose()
    setSendTokenTxRaw(null)
    setStakeTokenTxRaw(null)
    setIsIbcTransferSkipped(false)
    setIbcTransferAmountAtom(0)
    setIbcTransferTx(null)
    setIbcTransferTxStatus('pending')
    resetSignSendToken()
    resetBroadcastSendToken()
    resetSignStakeToken()
    resetBroadcastStakeToken()
  }

  const stepper = useStepper({
    steps,
    warning: ibcTransferTxStatus === 'return-later',
    error: Boolean(signSendTokenError || broadcastSendTokenError || signStakeTokenError || broadcastStakeTokenError),
    isOpen,
    isLoading: isBroadcastingSendToken || isBroadcastingStakeToken,
    isComplete: false,
    onClose: handleClose,
    onComplete: onSuccess
  })

  return (
    <StepperProvider {...stepper}>
      <StepperModal title="Liquid Staking" amount={formattedAmount}>
        <StakeModalStepOne amount={amount} />
        <StakeModalStepTwo amount={amount} />
        <StakeModalStepThree amount={amount} />
        <StakeModalStepFour amount={amount} />
      </StepperModal>
    </StepperProvider>
  )
}

export { StakeModal }
