import React from 'react'
import BigNumber from 'bignumber.js'
import { Button, Space } from '@mantine/core'
import { TransactionCard } from '@/components'
import { config } from '@/config'
import { LiquidStakeStatus, LiquidStakeMetaData } from '@/atoms/Wallet'
import { useHostZoneQuery } from '@/queries'
interface LiquidStakeMetaDataCardProps {
  meta: LiquidStakeMetaData
  onDismiss: (id: string) => void
}

const LiquidStakeMetaDataCard: React.FC<LiquidStakeMetaDataCardProps> = ({ meta, onDismiss }) => {
  const { data: hostZone } = useHostZoneQuery()

  const redemptionRate = new BigNumber(hostZone?.redemption_rate ?? 1)

  const formattedAmount = `${new BigNumber(meta.values.amount).decimalPlaces(3).toString()} ${meta.values.denom}`

  const formattedStakedAmount = `${new BigNumber(meta.values.amount)
    .dividedBy(redemptionRate)
    .decimalPlaces(5)
    .toString()} st${meta.values.denom}`

  const titles: Record<LiquidStakeStatus, string> = {
    pending: `Staking ${formattedAmount}...`,
    error: `Error · Staking ${formattedAmount}`,
    complete: `${formattedAmount} staked!`
  }

  const description: Record<LiquidStakeStatus, string> = {
    pending:
      'This could take 30 seconds or longer if the network is congested. If you exit Stride, this status may not be visible when you return, but the transfer will continue. Once the transfer is complete, you will need to return to initiate the staking process.',
    error:
      'This transfer could not be completed. Your tokens have not been moved to Stride. You can try again if you haven’t already.',
    complete: `You staked ${formattedAmount} on Stride and ${formattedStakedAmount} has been added to your wallet`
  }

  const isPersisted = Boolean(meta.values.hash && meta.minimizeId == null)

  return (
    <TransactionCard
      status={meta.values.status}
      title={titles[meta.values.status]}
      onDismiss={() => onDismiss(meta.values.hash)}
      description={description[meta.values.status]}>
      {isPersisted && (
        <>
          <Space h="md" />

          <Button
            component="a"
            target="_blank"
            href={`${config.network.mintscanTxUrl}/${meta.values.hash}`}
            variant="outline"
            color="dark">
            View details
          </Button>
        </>
      )}
    </TransactionCard>
  )
}

export { LiquidStakeMetaDataCard }
