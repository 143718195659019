import React from 'react'
import { useAtomValue } from 'jotai'
import { Button } from '@mantine/core'
import { StepperModalContent, useStepperContext } from '@/components'
import { CHAIN_CONFIG } from '@/config'
import { fatal } from '@/utils'
import { selectedAccountAtom } from '@/atoms/Wallet'

const StakeLiquidityPoolStepThree: React.FC = () => {
  const selectedAccount = useAtomValue(selectedAccountAtom)

  // We'll intentionally use `forceClose` instead of `close` because technically this
  // flow will never be "complete" (the previous step doesn't call the `complete` method)
  const { forceClose } = useStepperContext()

  if (!selectedAccount) {
    throw fatal('Unable to render final step of osmosis lp while disconnected.')
  }

  const poolUrl = CHAIN_CONFIG[selectedAccount.currency.coinDenom].poolUrl

  return (
    <StepperModalContent
      title={`Add your tokens to the pool`}
      description={`Your st${selectedAccount.currency.coinDenom} has been transferred to Osmosis. You can now add them to the liquidity pool there. Remember to bond your shares to earn rewards.`}
      actions={
        <>
          <Button variant="outline" color="dark" onClick={forceClose}>
            Back to Stride
          </Button>

          <Button component="a" href={poolUrl} target="_blank">
            Go to Osmosis
          </Button>
        </>
      }
    />
  )
}

export { StakeLiquidityPoolStepThree }
