import type { MsgTransfer } from 'cosmjs-types/ibc/applications/transfer/v1/tx'
import { MetaDataHandlerParameters } from './types'

const handleIbcLiquidityPool = ({
  message,
  strideAccount,
  selectedAccount,
  osmosisAccount
}: MetaDataHandlerParameters<MsgTransfer>): boolean => {
  if (!osmosisAccount) {
    return false
  }

  if (message.token == null) {
    return false
  }

  if (!message.token.denom.startsWith('st')) {
    return false
  }

  const isTransferredForOsmosisLiquidityPool =
    message.sender === strideAccount.key.bech32Address &&
    message.receiver === osmosisAccount.key.bech32Address &&
    message.token.denom === `st${selectedAccount.currency.coinMinimalDenom}`

  return isTransferredForOsmosisLiquidityPool
}

export { handleIbcLiquidityPool }
