import React from 'react'
import BigNumber from 'bignumber.js'
import { useAtomValue } from 'jotai'
import { Button } from '@mantine/core'
import { fatal } from '@/utils'
import { StepperModalContent, useStepperContext } from '@/components'
import { selectedAccountAtom } from '@/atoms/Wallet'
import { useWithdrawStToken } from './WithdrawStTokenContext'
import { withdrawStTokenAmountAtom } from '../atoms'
import { CHAIN_INFO_LIST } from '@/config'

const WithdrawStTokenStepOne: React.FC = () => {
  const { signWithdrawStToken, isSigningWithdrawStToken, signWithdrawStTokenError } = useWithdrawStToken()

  const { start, nextStep, forceClose } = useStepperContext()

  const selectedAccount = useAtomValue(selectedAccountAtom)

  const amount = useAtomValue(withdrawStTokenAmountAtom)

  if (!selectedAccount) {
    throw fatal('Unable to withdraw st tokens while disconnected')
  }

  const chainInfo = CHAIN_INFO_LIST[selectedAccount.currency.coinDenom]

  const handleStart = async () => {
    start()
    await signWithdrawStToken()
    nextStep()
  }

  const handleRetry = () => {
    handleStart()
  }

  if (signWithdrawStTokenError) {
    return (
      <StepperModalContent
        // User must fund the entire transaction with IBC and has no tokens on Stride
        title={`Transaction error`}
        description="This transfer could not be completed. Your tokens have not been moved."
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Back to Stride
            </Button>

            <Button color="dark" variant="outline" onClick={handleRetry}>
              Try again
            </Button>
          </>
        }
      />
    )
  }

  if (isSigningWithdrawStToken) {
    return (
      <StepperModalContent
        // User must fund the entire transaction with IBC and has no tokens on Stride
        title={`Approve the transaction in your wallet to continue`}
        description={`This will start the transfer of your st${selectedAccount.currency.coinDenom} tokens to your ${chainInfo.chainName} wallet.`}
      />
    )
  }

  const formattedAmount = new BigNumber(amount).decimalPlaces(3).toString()

  return (
    <StepperModalContent
      // User must fund the entire transaction with IBC and has no tokens on Stride
      title={`You’re about to withdraw ${formattedAmount} st${selectedAccount.currency.coinDenom} from Stride`}
      description={`This will move tokens from your balance on the Stride app to your ${chainInfo.chainName} wallet.`}
      actions={
        <>
          <Button variant="outline" color="dark" onClick={forceClose}>
            Cancel
          </Button>

          <Button onClick={handleStart}>Withdraw</Button>
        </>
      }
    />
  )
}

export { WithdrawStTokenStepOne }
