import axios from 'axios'
import { useAtomValue } from 'jotai'
import { useQuery } from 'react-query'
import { fatal } from '@/utils'
import { STRIDE_CHAIN_INFO } from '@/config'
import { queryKeys } from '@/query-keys'
import { liquidityPoolTransactionHashAtom } from '@/page-components/Stake/atoms'
import { selectedAccountAtom, TxResponse } from '@/atoms/Wallet'

interface TransactionByHashResponse {
  tx_response: TxResponse
}

// Our first approach was to calculate the staked token amount
// client-side. Problem is there's been a lot of errors
// transferring a value precisionally higher user's real stToken amount.
// To fix this, we are going a bit out of way to actually get
// the amount of st token gained from the liquid stake flow.
// In addition, we'll round down to the 5th decimal place in
// the signing mutation so we don't hit a precision error (if that's even
// possible).
const useLiquidStakeTransactionAmountQuery = () => {
  const hash = useAtomValue(liquidityPoolTransactionHashAtom)

  const selectedAccount = useAtomValue(selectedAccountAtom)

  const handleRequest = async () => {
    if (!hash) {
      throw fatal('Unable to query transaction hash when it is not defined.')
    }

    if (!selectedAccount) {
      throw fatal('Unable to query stake transaction while disconnected.')
    }

    const instance = axios.create({
      baseURL: STRIDE_CHAIN_INFO.rest
    })

    const response = await instance.get<TransactionByHashResponse>(`cosmos/tx/v1beta1/txs/${hash}`)

    const log = response.data.tx_response.logs[0]

    if (log == null) {
      throw fatal('Missing liquid staking transaction logs')
    }

    const event = log.events.find((event) => {
      return event.type === 'transfer'
    })

    if (event == null) {
      throw fatal('Missing liquid staking transfer event')
    }

    const stakedMinimalDenom = `st${selectedAccount.currency.coinMinimalDenom}`

    const attribute = event.attributes.find((attribute) => {
      return attribute.value.endsWith(stakedMinimalDenom)
    })

    if (attribute == null) {
      throw fatal('Missing amount attribute from liquing staking transfer event.')
    }

    const value = Number(attribute.value.replace(stakedMinimalDenom, ''))

    if (isNaN(value)) {
      throw fatal('Unable to get liquid staked value for osmosis liquidity pools')
    }

    // @IMPORTANT: This is a micro-denom unlike the stuff filled by useConnectWallet
    // e.g., strideAccountAtom, selectedAccountAtom, osmosisAccountAtom
    return { amount: value }
  }

  return useQuery(queryKeys.liquidityPoolStakeTransactionByHash({ hash: hash ?? '' }), handleRequest, {
    enabled: Boolean(hash)
  })
}

export { useLiquidStakeTransactionAmountQuery }
