import axios from 'axios'
import { useAtomValue } from 'jotai'
import { useQuery } from 'react-query'
import { CHAIN_INFO_LIST } from '@/config'
import { fatal } from '@/utils'
import { queryKeys } from '@/query-keys'
import {
  strideAccountAtom,
  selectedAccountAtom,
  osmosisAccountAtom,
  IBCMetaData,
  IBCTransferStatus,
  TxQueryResponse,
  IBCLiquidityPoolMetaData,
  IBCWithdrawStTokenMetaData
} from '@/atoms/Wallet'

interface IbcStatusQueryParameters {
  transaction: IBCMetaData | IBCLiquidityPoolMetaData | IBCWithdrawStTokenMetaData
}

interface IbcStatusQueryData {
  status: IBCTransferStatus
}

const useIbcStatusQuery = ({ transaction }: IbcStatusQueryParameters) => {
  const strideAccount = useAtomValue(strideAccountAtom)

  const selectedAccount = useAtomValue(selectedAccountAtom)

  const osmosisAccount = useAtomValue(osmosisAccountAtom)

  const handleRequest = async (): Promise<IbcStatusQueryData> => {
    if (!strideAccount || !selectedAccount) {
      throw fatal(`Unable to load ibc status of transaction (${transaction.values.hash}) while disconnected.`)
    }

    if (!transaction.values.sequence || !transaction.values.dstChannel) {
      // @TODO: This should be an error, let's fix IBC transfer first
      return { status: 'timeout' }
    }

    const getRelayerChainInfo = () => {
      if (transaction.values.sender === strideAccount.key.bech32Address) {
        return CHAIN_INFO_LIST[strideAccount.currency.coinDenom]
      }

      if (transaction.values.sender === selectedAccount.key.bech32Address) {
        return CHAIN_INFO_LIST[selectedAccount.currency.coinDenom]
      }

      if (transaction.values.sender.startsWith('osmo')) {
        if (!osmosisAccount) {
          // This is likely a mistake as transaction history query at this time should be
          // filtering out osmosis lp transactions while osmosis is down
          throw fatal(`Unable to get status of IBC transfer ${transaction.values.hash} while Osmosis is down.`)
        }

        return CHAIN_INFO_LIST[osmosisAccount.currency.coinDenom]
      }

      // On the unlikely event that the user gets here, it's likely
      // `useTransactionHistoryQuery` is not able to filter out the data properly.
      throw fatal(`IBC Transaction ${transaction.values.hash} has an invalid receiver.`)
    }

    const instance = axios.create({
      baseURL: getRelayerChainInfo().rest
    })

    // Generate url parameters to query acknowledge_packet or timeout_packet
    const createEventQueryParameters = (prefix: string): URLSearchParams => {
      const parameters = new URLSearchParams()
      parameters.append('events', `${prefix}_packet.packet_sequence='${transaction.values.sequence}'`)
      parameters.append('events', `${prefix}_packet.packet_dst_channel='${transaction.values.dstChannel}'`)
      parameters.append('pagination.limit', '1')
      return parameters
    }

    const eventQueryParameters = {
      acknowledge: createEventQueryParameters('acknowledge'),
      timeout: createEventQueryParameters('timeout')
    }

    const [acknowledgePacketResponse, timeoutPacketResponse] = await Promise.all([
      instance.get<TxQueryResponse>(`cosmos/tx/v1beta1/txs?${eventQueryParameters.acknowledge}`),
      instance.get<TxQueryResponse>(`cosmos/tx/v1beta1/txs?${eventQueryParameters.timeout}`)
    ])

    if (acknowledgePacketResponse.data.tx_responses.length) {
      return { status: 'complete' }
    }

    if (timeoutPacketResponse.data.tx_responses.length) {
      return { status: 'timeout' }
    }

    return { status: 'pending' }
  }

  return useQuery(queryKeys.transactionHistoryIbcStatusByHash({ hash: transaction.values.hash }), handleRequest, {
    // We want to force poll the data every 30 seconds if we found the transaction to be pending.
    // staleTime doesn't necessarily force this query to refetch.
    refetchInterval: (data) => (data?.status === 'pending' ? 30 * 1000 : false)
  })
}

export { useIbcStatusQuery }
