import React, { useMemo } from 'react'
import BigNumber from 'bignumber.js'
import { useAtom } from 'jotai'
import { Button, Space } from '@mantine/core'
import { TransactionCard, TransactionCardStatus } from '@/components'
import { config, CHAIN_INFO_LIST } from '@/config'
import { IBCMetaData, selectedAccountAtom } from '@/atoms/Wallet'
import { useIbcStatusQuery } from './queries'
import { fatal } from '@/utils'

interface IBCMetaDataCardProps {
  meta: IBCMetaData
  onDismiss: (id: string) => void
}

const IBCMetaDataCard: React.FC<IBCMetaDataCardProps> = ({ meta, onDismiss }) => {
  const [selectedAccount] = useAtom(selectedAccountAtom)

  const { data, error } = useIbcStatusQuery({ transaction: meta })

  const cardStatus = useMemo(() => {
    if (error) return 'checking-failed'
    if (data == null) return 'checking'
    if (data.status === 'return-later') return 'pending'
    if (data.status === 'timeout') return 'error'
    return data.status
  }, [data, error])

  if (!selectedAccount) {
    throw fatal('Unable to render ibc transaction while disconnected.')
  }

  const chainInfo = CHAIN_INFO_LIST[selectedAccount.currency.coinDenom]

  const formattedAmount = `${new BigNumber(meta.values.amount).decimalPlaces(3).toString()} ${meta.values.denom}`

  const titles: Record<TransactionCardStatus, () => string> = {
    checking: () => `Transfer of ${formattedAmount} ${meta.type === 'withdraw' ? 'from' : 'to'} Stride`,
    'checking-failed': () => `Transfer of ${formattedAmount} ${meta.type === 'withdraw' ? 'from' : 'to'} Stride`,
    pending: () => `Transferring ${formattedAmount} ${meta.type === 'withdraw' ? 'from' : 'to'} Stride`,
    error: () => `Error transferring ${formattedAmount}`,
    complete: () =>
      meta.type === 'withdraw' ? `${formattedAmount} transferred from Stride` : `Ready to stake ${formattedAmount}`
  }

  const description: Record<TransactionCardStatus, () => string> = {
    checking: () => 'Checking the status of your IBC transfer.',
    'checking-failed': () => 'We were unable to check the status of this IBC transaction. Please refresh the page.',
    pending: () =>
      'This could take 30 seconds or longer if the network is congested. If you exit Stride, this status may not be visible when you return, but the transfer will continue.',
    complete: () =>
      meta.type === 'withdraw'
        ? `${formattedAmount} has been successfully transferred from Stride to your wallet on ${chainInfo.chainName}. The tokens are visible at your ${chainInfo.chainName} address.`
        : `${formattedAmount} has been successfully transferred to Stride and is now ready to be staked.`,
    error: () =>
      'This transfer could not be completed. Your tokens have not been moved to Stride. You can try again if you haven’t already.'
  }

  const isPersisted = cardStatus === 'complete' && Boolean(meta.values.hash) && meta.minimizeId == null

  return (
    <TransactionCard
      status={cardStatus}
      title={titles[cardStatus]()}
      onDismiss={() => onDismiss(meta.values.hash)}
      description={description[cardStatus]()}>
      {isPersisted && (
        <>
          <Space h="md" />

          <Button
            component="a"
            target="_blank"
            href={`${config.network.mintscanTxUrl}/${meta.values.hash}`}
            variant="outline"
            color="dark">
            View details
          </Button>
        </>
      )}
    </TransactionCard>
  )
}

export { IBCMetaDataCard }
