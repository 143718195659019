import { useMemo } from 'react'
import { useAtom } from 'jotai'
import { useForm, zodResolver } from '@mantine/form'
import z from 'zod'
import { selectedAccountBalanceAtom, strideAccountSelectedCoinAmountAtom } from '@/atoms/Wallet'

export interface StakeFormType {
  amount: string
}

const useStakeForm = () => {
  const [balance] = useAtom(selectedAccountBalanceAtom)

  const [strideBalance] = useAtom(strideAccountSelectedCoinAmountAtom)

  const totalBalance = balance + strideBalance

  const schema = useMemo(() => {
    return z.object({
      // @TODO: Maybe we should add a validation for the decimal precision so
      // they don't manually enter something that would fail the ibc like so: 0.02310230985
      amount: z.preprocess(
        (v) => Number(z.string().parse(v)),
        z
          .number({
            required_error: 'Amount is required.',
            invalid_type_error: 'Amount must be a number.'
          })
          .min(0.001, 'Amount must have a value more than 0.001.')
          .max(totalBalance, 'Amount exceeds your balance.')
      )
    })
  }, [totalBalance])

  return useForm<StakeFormType>({
    schema: zodResolver(schema),
    initialValues: { amount: '' }
  })
}

export { useStakeForm }
