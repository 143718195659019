import { useMutation } from 'react-query'
import { useAtomValue } from 'jotai'
import { CHAIN_CONFIG } from '@/config'
import {
  selectedAccountAtom,
  strideAccountAtom,
  convertDenomToMicroDenom,
  generateIbcTimeoutTimestamp,
  cleanMicroDenom
} from '@/atoms/Wallet'
import { withdrawStTokenAmountAtom } from '@/page-components/Stake/atoms'
import { fatal } from '@/utils'
import { MutationParameters } from './types'

const useSignWithdrawStTokenMutation = (parameters: MutationParameters) => {
  const selectedAccount = useAtomValue(selectedAccountAtom)

  const strideAccount = useAtomValue(strideAccountAtom)

  const amount = useAtomValue(withdrawStTokenAmountAtom)

  return useMutation(async (): Promise<void> => {
    if (!strideAccount || !selectedAccount) {
      throw fatal('You are unable to send token without connecting your wallet.')
    }

    const fee = {
      amount: [{ amount: '0', denom: strideAccount.currency.coinMinimalDenom }],
      gas: String(convertDenomToMicroDenom(0.25))
    }

    const stakedDenom = `st${selectedAccount.currency.coinMinimalDenom}`

    const amountInMicroDenom = convertDenomToMicroDenom(amount, selectedAccount.currency.coinMinimalDenom)

    const sourceChannel = CHAIN_CONFIG[selectedAccount.currency.coinDenom].withdrawChannel

    const msgTransfer = {
      typeUrl: '/ibc.applications.transfer.v1.MsgTransfer',
      value: {
        sourcePort: 'transfer',
        sourceChannel: sourceChannel,
        token: {
          amount: cleanMicroDenom(amountInMicroDenom),
          denom: stakedDenom
        },
        sender: strideAccount.key.bech32Address,
        receiver: selectedAccount.key.bech32Address,
        timeoutTimestamp: generateIbcTimeoutTimestamp()
      }
    }

    // We'll use account.stargate instead of account.client as stridejs.getSigningStridelabsClient
    // oes not contain default amino types for messages such as TX_MSG.MsgTransfer.
    // Let's get rid of this in the future once we've fixed this directly from stride.js
    // @TODO https://github.com/Stride-Labs/interface/issues/81
    parameters.encodedRef.current = await strideAccount.stargate.sign(
      strideAccount.key.bech32Address,
      [msgTransfer],
      fee,
      ''
    )
  })
}

export { useSignWithdrawStTokenMutation }
