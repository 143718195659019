import React, { useMemo } from 'react'
import BigNumber from 'bignumber.js'
import { useAtom, useAtomValue } from 'jotai'
import { selectedAccountDenomAtom } from '@/atoms/Wallet'
import { withdrawStTokenModalIsOpenAtom, withdrawStTokenAmountAtom } from '@/page-components/Stake/atoms'
import { StepperProvider, StepperModal, useStepper } from '@/components'
import { useWithdrawStToken } from './WithdrawStTokenContext'
import { WithdrawStTokenStepOne } from './WithdrawStTokenStepOne'
import { WithdrawStTokenStepTwo } from './WithdrawStTokenStepTwo'

const WithdrawStTokenModal: React.FC = () => {
  const {
    resetWithdrawStTokenState,
    signWithdrawStTokenError,
    isBroadcastingWithdrawStToken,
    broadcastWithdrawStTokenError,
    ibcStatusError,
    isIbcStatusLoading
  } = useWithdrawStToken()

  const [isOpen, setIsOpen] = useAtom(withdrawStTokenModalIsOpenAtom)

  const [amount, setAmount] = useAtom(withdrawStTokenAmountAtom)

  const denom = useAtomValue(selectedAccountDenomAtom)

  const formattedAmount = `${new BigNumber(amount).decimalPlaces(5).toString()} st${denom}`

  const steps = useMemo(() => {
    return ['Approve transfer in wallet', `Transfer st${denom}`]
  }, [denom])

  const handleClose = () => {
    setIsOpen(false)
    setAmount(0)
    resetWithdrawStTokenState()
  }

  const stepper = useStepper({
    steps,
    warning: isIbcStatusLoading,
    error: Boolean(signWithdrawStTokenError || broadcastWithdrawStTokenError || ibcStatusError),
    isOpen,
    isLoading: isBroadcastingWithdrawStToken || isIbcStatusLoading,
    isComplete: false,
    onClose: handleClose
  })

  return (
    <StepperProvider {...stepper}>
      <StepperModal title="Withdraw" amount={formattedAmount}>
        <WithdrawStTokenStepOne />
        <WithdrawStTokenStepTwo />
      </StepperModal>
    </StepperProvider>
  )
}

export { WithdrawStTokenModal }
