import React, { useMemo } from 'react'
import { useAtom } from 'jotai'
import { UnstakeModalStepOne } from './UnstakeModalStepOne'
import { UnstakeModalStepTwo } from './UnstakeModalStepTwo'
import { UnstakeModalStepThree } from './UnstakeModalStepThree'
import { UnstakeModalStepFour } from './UnstakeModalStepFour'
import { StepperModal, StepperProvider, useStepper } from '@/components'
import { selectedAccountDenomAtom, useWalletConnect } from '@/atoms/Wallet'
import { claimMetaDataAtom, unstakeAmountAtom, isUnstakingModalOpenAtom } from '@/page-components/Stake/atoms'
import { unstakeTokenTxRawAtom } from './atoms'

interface UnstakeModalProps {
  onSuccess: () => void
}

const UnstakeModal: React.FC<UnstakeModalProps> = ({ onSuccess }) => {
  const {
    isBroadcastingUnstakeTokens,
    signUnstakeTokensError,
    broadcastUnstakeTokensError,
    claimTokenError,
    resetSignUnstakeTokens,
    resetBroadcastUnstakeTokens
  } = useWalletConnect()

  const [denom] = useAtom(selectedAccountDenomAtom)

  const [, setUnstakeTokenTxRaw] = useAtom(unstakeTokenTxRawAtom)

  const [isOpen, setIsOpen] = useAtom(isUnstakingModalOpenAtom)

  const [claimMetaData, setClaimMetaData] = useAtom(claimMetaDataAtom)

  const [amount, setAmount] = useAtom(unstakeAmountAtom)

  const stakedDenom = `st${denom}`

  const title = claimMetaData ? 'Claiming' : 'Unstaking'

  const formattedAmount = claimMetaData
    ? `${claimMetaData.values.amount} ${claimMetaData.values.denom}`
    : `${amount} ${stakedDenom}`

  const steps = useMemo(() => {
    return ['Approve transfer in wallet', `Unstake tokens`, 'Unbonding (21-24 days)', `Claim ${denom}`]
  }, [denom])

  const handleClose = () => {
    setIsOpen(false)
    setAmount(0)
    setClaimMetaData(null)
    setUnstakeTokenTxRaw(null)
    resetSignUnstakeTokens()
    resetBroadcastUnstakeTokens()
  }

  const stepper = useStepper({
    steps,
    warning: false,
    error: Boolean(signUnstakeTokensError || broadcastUnstakeTokensError || claimTokenError),
    isOpen,
    isLoading: isBroadcastingUnstakeTokens,
    isComplete: false,
    onClose: handleClose,
    onComplete: onSuccess
  })

  return (
    <StepperProvider {...stepper}>
      <StepperModal title={title} amount={formattedAmount}>
        <UnstakeModalStepOne />
        <UnstakeModalStepTwo />
        <UnstakeModalStepThree />
        <UnstakeModalStepFour />
      </StepperModal>
    </StepperProvider>
  )
}

export { UnstakeModal }
