import React, { useState } from 'react'
import { useAtom } from 'jotai'
import BigNumber from 'bignumber.js'
import { Box, Group, Text, Popover, UnstyledButton } from '@mantine/core'
import { CHAIN_INFO_LIST, SUPPORTED_COIN_DENOMS } from '@/config'
import { selectedCoinDenomAtom, SelectedCoinDenom } from '@/atoms/Wallet'
import { useStakeStatsQuery } from '@/queries'
import { Icon, TextHeading, TextLoader } from '@/components'

interface CoinSelectorProps {
  isApyDisabled?: boolean
}

const CoinSelector: React.FC<CoinSelectorProps> = ({ isApyDisabled = false }) => {
  const [isOpen, setIsOpen] = useState(false)

  const [selectedCoinDenom, setSelectedCoinDenom] = useAtom(selectedCoinDenomAtom)

  const { data: stakeStats, isLoading: isStakeStatsLoading } = useStakeStatsQuery()

  const handleSelectCoin = (coin: SelectedCoinDenom) => {
    return () => {
      setSelectedCoinDenom(coin)
      setIsOpen(false)
    }
  }

  const handleCloseDropdown = () => {
    setIsOpen(false)
  }

  const handleToggleDropdown = () => {
    setIsOpen((isOpen) => !isOpen)
  }

  return (
    <Popover
      target={
        <UnstyledButton
          pl="xs"
          pr="xs"
          onClick={handleToggleDropdown}
          sx={(t) => ({
            position: 'relative',
            left: -t.spacing.xs,
            borderRadius: t.radius.sm,
            '&:hover': { background: t.colors.cyan[1] }
          })}>
          <Group spacing="md">
            <TextHeading>{selectedCoinDenom}</TextHeading>

            <Box sx={{ transform: 'rotate(180deg)' }}>
              <Icon name="caretDown" size="lg" />
            </Box>
          </Group>
        </UnstyledButton>
      }
      opened={isOpen}
      onClose={handleCloseDropdown}
      width={434}
      shadow="lg"
      spacing={4}
      position="bottom"
      placement="start">
      {SUPPORTED_COIN_DENOMS.map((denom, i) => {
        const chainInfo = CHAIN_INFO_LIST[denom]

        const selected = denom === selectedCoinDenom

        return (
          <UnstyledButton
            key={i}
            onClick={handleSelectCoin(denom)}
            sx={(t) => ({
              width: '100%',
              padding: t.spacing.sm,
              background: selected ? t.colors.gray[0] : 'transparent',
              color: t.colors.gray[8],
              '&:hover': { background: t.colors.gray[0] }
            })}>
            <Text component="span" size="lg" transform="uppercase" weight={700} inline>
              {denom}
            </Text>

            <Text component="span" size="lg">
              {' · '}
              {chainInfo.chainName}
            </Text>

            {!isApyDisabled && (
              <>
                <Text component="span" size="lg">
                  {' · '}
                </Text>

                <TextLoader loading={isStakeStatsLoading} size="lg" component="span">
                  {stakeStats == null
                    ? '0%'
                    : `${new BigNumber(stakeStats[denom].strideYield).multipliedBy(100).decimalPlaces(2).toString()}%`}
                </TextLoader>
              </>
            )}
          </UnstyledButton>
        )
      })}
    </Popover>
  )
}

export { CoinSelector }
