import { selectedAccountDenomAtom, useWalletConnect } from '@/atoms/Wallet'
import { StepperModalContent, useStepperContext } from '@/components'
import { useMount } from '@/hooks'
import { Button } from '@mantine/core'
import { useAtom } from 'jotai'
import React from 'react'
import { isIbcTransferSkippedAtom, stakeTokenTxRawAtom } from './atoms'

interface StakeModalStepThreeProps {
  amount: number
}

const StakeModalStepThree: React.FC<StakeModalStepThreeProps> = ({ amount }) => {
  const { signStakeToken, signStakeTokenError } = useWalletConnect()

  const [denom] = useAtom(selectedAccountDenomAtom)

  const [, setStakeTokenRxRaw] = useAtom(stakeTokenTxRawAtom)

  const [isIbcTransferSkipped] = useAtom(isIbcTransferSkippedAtom)

  const { nextStep, forceClose } = useStepperContext()

  useMount(() => {
    handleStep()
  })

  const handleStep = async () => {
    const raw = await signStakeToken(amount)
    setStakeTokenRxRaw(raw)
    nextStep()
  }

  const handleRetrySignStakeToken = async () => {
    handleStep()
  }

  const stepperModalContentTitle = isIbcTransferSkipped
    ? `Great! Approve in your wallet to start staking your ${denom}`
    : `Transfer complete! Approve in your wallet again to start staking your ${denom}`

  if (signStakeTokenError) {
    return (
      <StepperModalContent
        title="Transaction error"
        description={
          <>
            {' '}
            This transfer could not be completed. Your tokens are on Stride, but have not been staked. Please try again.
          </>
        }
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Back to Stride
            </Button>
            <Button color="dark" variant="outline" onClick={handleRetrySignStakeToken}>
              Try again
            </Button>
          </>
        }
      />
    )
  }

  return (
    <StepperModalContent
      title={stepperModalContentTitle}
      description={`This will initiate staking and the transfer of ${denom} to your wallet`}
    />
  )
}

export { StakeModalStepThree }
