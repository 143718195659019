import { useMutation } from 'react-query'
import { useAtomValue } from 'jotai'
import { CHAIN_CONFIG } from '@/config'
import {
  selectedAccountAtom,
  strideAccountAtom,
  osmosisAccountAtom,
  convertDenomToMicroDenom,
  generateIbcTimeoutTimestamp,
  cleanMicroDenom
} from '@/atoms/Wallet'
import { fatal } from '@/utils'
import { useLiquidStakeTransactionAmountQuery } from '../queries'
import { MutationParameters } from './types'

const useSignStakeToLiquidityPoolMutation = (parameters: MutationParameters) => {
  const selectedAccount = useAtomValue(selectedAccountAtom)

  const strideAccount = useAtomValue(strideAccountAtom)

  const osmosisAccount = useAtomValue(osmosisAccountAtom)

  const { data: stakeTransactionAmount } = useLiquidStakeTransactionAmountQuery()

  return useMutation(async (): Promise<void> => {
    if (!strideAccount || !selectedAccount || !osmosisAccount) {
      throw fatal('You are unable to send token without connecting your wallet.')
    }

    if (!stakeTransactionAmount) {
      throw fatal('Unable to sign ibc transfer for osmosis liquidity pool while transaction amount is null.')
    }

    const fee = {
      amount: [
        {
          amount: '0',
          denom: strideAccount.currency.coinMinimalDenom
        }
      ],
      gas: String(convertDenomToMicroDenom(0.25))
    }

    const stakedDenom = `st${selectedAccount.currency.coinMinimalDenom}`

    const msgTransfer = {
      typeUrl: '/ibc.applications.transfer.v1.MsgTransfer',
      value: {
        sourcePort: 'transfer',
        sourceChannel: CHAIN_CONFIG.OSMO.withdrawChannel,
        token: {
          amount: cleanMicroDenom(stakeTransactionAmount.amount),
          denom: stakedDenom
        },
        sender: strideAccount.key.bech32Address,
        receiver: osmosisAccount.key.bech32Address,
        timeoutTimestamp: generateIbcTimeoutTimestamp()
      }
    }

    // We'll use account.stargate instead of account.client as stridejs.getSigningStridelabsClient
    // oes not contain default amino types for messages such as TX_MSG.MsgTransfer.
    // Let's get rid of this in the future once we've fixed this directly from stride.js
    // @TODO https://github.com/Stride-Labs/interface/issues/81
    parameters.encodedRef.current = await strideAccount.stargate.sign(
      strideAccount.key.bech32Address,
      [msgTransfer],
      fee,
      ''
    )
  })
}

export { useSignStakeToLiquidityPoolMutation }
