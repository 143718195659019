import React from 'react'
import { Button } from '@mantine/core'
import { StepperModalContent, useStepperContext } from '@/components'

const UnstakeModalStepThree: React.FC = () => {
  const { forceClose } = useStepperContext()

  return (
    <StepperModalContent
      title="Unstake complete! Now for the unbonding..."
      description="You can leave Stride and return to claim your tokens once unbonding is complete. Connect your wallet to Stride anytime to see the status of this process."
      actions={<Button onClick={forceClose}>Got it</Button>}
    />
  )
}

export { UnstakeModalStepThree }
