import { useQuery } from 'react-query'
import { useAtomValue } from 'jotai'
import { redis } from '@/redis'
import { CHAIN_CONFIG } from '@/config'
import { queryKeys } from '@/query-keys'
import { fatal } from '@/utils'
import { selectedAccountAtom, osmosisAccountAtom } from '@/atoms/Wallet'

const useLiquidityPoolApyQuery = () => {
  const selectedAccount = useAtomValue(selectedAccountAtom)

  const osmosisAccount = useAtomValue(osmosisAccountAtom)

  // We'll check for both instead of asserting non-null since this query hook
  // is likely called in the Modal which is rendered along the rest of the page.
  const isQualifiedForLiquidityPoolFlow = Boolean(
    osmosisAccount && selectedAccount && CHAIN_CONFIG[selectedAccount.currency.coinDenom].poolId
  )

  const handleRequest = async (): Promise<{ apy: number }> => {
    if (!selectedAccount) {
      throw fatal('Unable to fetch liquidity pool apy while disconnected.')
    }

    const poolId = CHAIN_CONFIG[selectedAccount.currency.coinDenom].poolId

    const value = await redis.get<number>(`sheet_POOL_TOTAL_APR_${poolId}`)

    return { apy: value ?? 0 }
  }

  return useQuery(
    queryKeys.stakingModalLiquidityPoolApyByDenom({ denom: selectedAccount?.currency.coinDenom ?? '' }),
    handleRequest,
    { enabled: Boolean(isQualifiedForLiquidityPoolFlow) }
  )
}

export { useLiquidityPoolApyQuery }
