import React, { useContext, useRef } from 'react'
import { DeliverTxResponse } from '@cosmjs/stargate'
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx'
import { fatal } from '@/utils'
import { IBCTransferStatus } from '@/atoms/Wallet'
import {
  useSignStakeToLiquidityPoolMutation,
  useBroadcastStakeToLiquidityPoolMutation,
  useTraceIbcTransfer,
  MutationParameters
} from './mutations'

interface StakeLiquidityContextType {
  resetLiquidityPoolState: () => void

  signStakeToLiquidityPool: () => Promise<void>
  isSigningStakeToLiquidityPool: boolean
  signStakeToLiquidityPoolError: unknown

  broadcastStakeToLiquidityPool: () => Promise<{ status: IBCTransferStatus }>
  isBroadcastingStakeToLiquidityPool: boolean
  broadcastStakeToLiquidityPoolError: unknown

  ibcStatus: { status: IBCTransferStatus } | undefined
  traceIbcStatus: () => Promise<{ status: IBCTransferStatus }>
  isIbcStatusLoading: boolean
  ibcStatusError: unknown
}

const StakeLiquidityContext = React.createContext<StakeLiquidityContextType | null>(null)

const StakeLiquidityPoolProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const encodedRef = useRef<TxRaw | null>(null)

  const transactionRef = useRef<DeliverTxResponse | null>(null)

  const parameters: MutationParameters = {
    encodedRef: encodedRef,
    transactionRef: transactionRef
  }

  const {
    mutateAsync: signStakeToLiquidityPool,
    isLoading: isSigningStakeToLiquidityPool,
    error: signStakeToLiquidityPoolError,
    reset: resetSignStakeToLiquidityPool
  } = useSignStakeToLiquidityPoolMutation(parameters)

  const {
    mutateAsync: broadcastStakeToLiquidityPool,
    isLoading: isBroadcastingStakeToLiquidityPool,
    error: broadcastStakeToLiquidityPoolError,
    reset: resetBroadcastStakeToLiquidityPool
  } = useBroadcastStakeToLiquidityPoolMutation(parameters)

  const {
    data: ibcStatus,
    mutateAsync: traceIbcStatus,
    isLoading: isIbcStatusLoading,
    error: ibcStatusError,
    reset: resetIbcStatus
  } = useTraceIbcTransfer(parameters)

  const resetLiquidityPoolState = () => {
    encodedRef.current = null
    transactionRef.current = null
    resetSignStakeToLiquidityPool()
    resetBroadcastStakeToLiquidityPool()
    resetIbcStatus()
  }

  return (
    <StakeLiquidityContext.Provider
      value={{
        resetLiquidityPoolState,

        signStakeToLiquidityPool,
        isSigningStakeToLiquidityPool,
        signStakeToLiquidityPoolError,

        broadcastStakeToLiquidityPool,
        isBroadcastingStakeToLiquidityPool,
        broadcastStakeToLiquidityPoolError,

        ibcStatus,
        traceIbcStatus,
        isIbcStatusLoading,
        ibcStatusError
      }}>
      {children}
    </StakeLiquidityContext.Provider>
  )
}

const useStakeLiquidityPool = () => {
  const context = useContext(StakeLiquidityContext)

  if (context == null) {
    throw fatal('You forgot to mount StakeLiquidityProvider.')
  }

  return context
}

export { StakeLiquidityPoolProvider, useStakeLiquidityPool }
