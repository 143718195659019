import React from 'react'
import { useAtomValue } from 'jotai'
import { Button } from '@mantine/core'
import { useMount } from '@/hooks'
import { fatal } from '@/utils'
import { selectedAccountAtom, osmosisAccountAtom } from '@/atoms/Wallet'
import { notify } from '@/atoms/Notifications'
import { StepperModalContent, useStepperContext } from '@/components'
import { liquidityPoolModalIsOpenAtom } from '../atoms'
import { useStakeLiquidityPool } from './StakeLiquidityPoolContext'

const StakeLiquidityPoolStepTwo: React.FC = () => {
  const osmosisAccount = useAtomValue(osmosisAccountAtom)

  const selectedAccount = useAtomValue(selectedAccountAtom)

  const {
    broadcastStakeToLiquidityPool,
    isBroadcastingStakeToLiquidityPool,
    broadcastStakeToLiquidityPoolError,
    traceIbcStatus,
    isIbcStatusLoading
  } = useStakeLiquidityPool()

  const isOpen = useAtomValue(liquidityPoolModalIsOpenAtom)

  const { nextStep, handleClose, forceClose } = useStepperContext()

  const handleStep = async () => {
    const { status } = await broadcastStakeToLiquidityPool()

    if (!isOpen) {
      // This function doesn't get cancelled when the modal gets closed.
      // If we get here when pool is already closed, it's likely that the stuff that
      // `traceIbcStatus` (transactionRef) needs has already been reset by the
      // modal close handler. It's unlikely this bug is affecting anyone, but this will
      // help us prevent false positives in Rollbar.
      return
    }

    if (status === 'return-later') {
      // If we're here, it means that our ibc transfer took too long and we're tracking it
      // the second time without the 30-second timeout. This way, the user can continue the
      // flow if it they haven't closed.
      //
      // We can check for the return-later status by checking using `isIbcStatusLoading`
      // to check if the ibc transfer hasn't completed and ibc tracking is still on-going.
      //
      // @TODO: Handle timeout state if this doesn't succeed. The original implementation
      // from the ibc/staking modal doesn't have this yet neither. Add display states for
      // `status === 'timeout'` for both broadcast and tracing and `ibcStatusError`
      await traceIbcStatus()
    }

    nextStep()
  }

  useMount(() => {
    handleStep()
  })

  const handleRetry = () => {
    handleStep()
  }

  const handleCloseCallback = () => {
    if (!selectedAccount || !osmosisAccount) {
      throw fatal('Unable to stake to liquidity pool while disconnected.')
    }

    // If it was closed after the broadcast, we don't need to prepend.
    if (!isBroadcastingStakeToLiquidityPool && !isIbcStatusLoading) {
      return
    }

    notify.progress('Transaction minimized', "We'll let you know when the transfer completes.")
  }

  handleClose(handleCloseCallback)

  if (!selectedAccount) {
    throw fatal('Unable to complete ibc transfer for osmosis liquidity pools while disconnected.')
  }

  if (isIbcStatusLoading) {
    return (
      <StepperModalContent
        title="This transaction is taking longer than usual"
        description={`If you don’t want to wait here, you can close this dialog. Your transaction will continue and you’ll be able to see status on the Stride app page.`}
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Back to Stride
            </Button>
          </>
        }
      />
    )
  }

  if (broadcastStakeToLiquidityPoolError) {
    return (
      <StepperModalContent
        title={`Transaction error`}
        description="This transfer could not be completed. Your tokens have not been moved."
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Back to Stride
            </Button>

            <Button color="dark" variant="outline" onClick={handleRetry}>
              Try again
            </Button>
          </>
        }
      />
    )
  }

  return (
    <StepperModalContent
      title={`Transferring your st${selectedAccount.currency.coinDenom} to Osmosis...`}
      description={`This will start the transfer of your st${selectedAccount.currency.coinDenom} tokens to Osmosis.`}
    />
  )
}

export { StakeLiquidityPoolStepTwo }
